import axios from 'axios'
import { showErrorToast } from '../helpers/generalErrorHandler'

async function fetchResults(catalogId, searchTerm) {
    if (!catalogId || !searchTerm) return

    try {
        const response = await axios.post(`/api/catalog/${catalogId}/search`, {
            query: searchTerm
        });

        return response.data.data
        
    } catch (error) {
        showErrorToast(error)
        throw error;
    }
}

export {
    fetchResults
}