<template>
    <p class="text-black fs-5 mb-3">{{ comments.length }} {{ commentTotalTranslation }}</p>
    <template v-if="!readOnly">
        <label class="form-label d-flex justify-content-between text-dark fw-normal fs-5 mb-1">
            <span v-if="showEditButton">{{ 'comment.edit'.trans() }}</span>
            <span v-else>{{ 'comment.current'.trans() }}</span>
            <span>{{ currentLength }}/{{ commentMaxLength }}</span>
        </label>
        <textarea class="form-control text-dark" rows="6"
            :placeholder="'comments.textarea.placeholder'.trans()"
            :maxlength="commentMaxLength"
            v-model="currentComment"
        ></textarea>
        <template v-if="showEditButton">
            <button class="btn btn-dark fw-bold fs-5 mt-2 text-uppercase"
                :disabled="currentLength <= 0"
                @click="submitEditComment"
            >
                {{ 'comment.edit'.trans() }}
            </button>
            <button class="btn btn-light fw-bold fs-5 mt-2 ms-2 text-uppercase"
                @click="revertComment"
            >
                {{ 'comment.revert'.trans() }}
            </button>
        </template>
        <button v-else
            class="btn btn-dark fw-bold fs-5 mt-2 text-uppercase"
            :disabled="currentLength <= 0"
            @click="submitNewComment"
        >
            {{ 'comment.submit'.trans() }}
        </button>
    </template>
    <div class="mt-3">
        <div v-for="comment in comments" :key="comment.id">
            <div class="d-flex align-items-center">
                <span class="text-dark fw-bold fs-5">{{ formatName(comment.author.name) }}</span>
                <span class="text-muted fs-5 ms-2">{{ formatTime(comment.timestamp) }}</span>
                <span v-if="isFromMergedCard(comment)" class="text-muted fs-5 ms-1">({{ 'comment.from_merged_box'.trans() }})</span>
                <div v-if="comment.editable && !readOnly"
                    class="dropdown ms-auto"
                >
                    <button class="btn btn-outline p-0 dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <i class="fa fa-ellipsis-h text-dark fs-5"></i>
                    </button>
                    <div class="dropdown-menu p-3">
                        <p class="cursor-pointer d-flex align-items-center text-decoration-underline text-dark mb-1">
                            <i class="mdi mdi-pencil fs-4 me-1"></i>
                            <span class="mb-0 fs-5" @click="editComment(comment)">
                                {{ "comment.edit".trans() }}
                            </span>
                        </p>
                        <p class="cursor-pointer d-flex align-items-center text-decoration-underline text-dark mb-0">
                            <i class="mdi mdi-trash-can-outline fs-4 me-1"></i>
                            <span class="mb-0 fs-5"
                                data-bs-toggle="modal"
                                data-bs-target="#deleteCommentModal"
                                @click="deleteComment(comment)"
                            >
                                {{ "comment.delete".trans() }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <p class="text-dark fs-5 pb-2 mt-2 border-bottom">{{ comment.comment }}</p>
        </div>
    </div>
</template>

<script setup>
    import { computed, ref } from "vue"
    import { useCommentsStore } from '../../../js/frontend/stores/comments'
    import { newComment, updateComment } from '../../../js/frontend/api/comments'

    const props = defineProps({
        comments: {
            type: Object,
            required: true
        },
        id: {
            type: Number,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        readOnly: {
            type: Boolean,
            required: true
        },
        activePagesIds: {
            type: Array,
            required: false,
            default: []
        }
    })

    const commentsStore = useCommentsStore()

    const emit = defineEmits(['commentsUpdated'])

    const currentComment = ref('')
    const currentLength = computed(() => currentComment.value.length)
    const commentTotalTranslation = computed(() => {
        if (props.comments.length == 1) return 'comment.total.singular'.trans()
        else return 'comment.total'.trans()
    })

    const editedCommentId = ref(null)
    const showEditButton = ref(false)

    const commentMaxLength = 500

    async function submitNewComment() {
        try {
            await newComment(currentComment.value, props.id, props.type)
            emit('commentsUpdated')

        } catch (error) {
            throw error
        }
    }

    async function submitEditComment() {
        try {
            await updateComment(currentComment.value, editedCommentId.value)
            emit('commentsUpdated')

        } catch (error) {
            throw error
        }
    }

    function editComment(comment) {
        currentComment.value = comment.comment
        editedCommentId.value = comment.id
        showEditButton.value = true
    }

    function revertComment() {
        currentComment.value = ''
        showEditButton.value = false
    }

    function deleteComment(comment) {
        commentsStore.setCommentToBeDeleted(comment.id, props.type, props.id, props.activePagesIds)
    }

    function formatName(name) {
        let split = name.split(' ')

        if (split.length == 0) return name

        let lastPart = split.pop()

        return [...split, lastPart.substring(0, 1)].join(' ') + '.'
    }

    function formatTime(timestamp) {
        return `${timestamp.date} - ${timestamp.time}h`
    }

    function isFromMergedCard(comment) {
        if (!comment.metadata) return false

        if (!comment.metadata.originalCommentModelId) return false

        const metadataId = comment.metadata.originalCommentModelId.value

        return metadataId != props.id
    }
</script>

<style lang="sass" scoped>
    .mdi-pencil,
    .mdi-trash-can-outline
        margin-top: 3px

    .dropdown-menu
        width: max-content
</style>