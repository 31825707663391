<template>
    <div
        class="modal fade"
        id="splitCardModal"
        tabindex="-1"
        aria-labelledby="splitCardModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        ref="modal"
    >
        <div class="modal-dialog">
            <div class="modal-content p-3">
                <div class="modal-body text-black">
                    <h3
                        class="modal-title text-black mb-3 fw-bold"
                        id="splitCardModalLabel"
                    >
                        {{ "split_card.modal.title".trans() }}
                    </h3>

                    <h5>
                        {{ "split_card.modal.text".trans() }}
                    </h5>

                    <p class="mb-1 mt-2 fw-normal text-dark fs-5">
                        {{ "split_card.modal.choose_card".trans() }}
                    </p>
                    <div class="position-relative">
                        <select ref="select"></select>
                        <i
                            class="mdi mdi-triangle fs-7 text-dark position-absolute"
                        ></i>
                    </div>

                    <div class="split-card-products mt-3" v-if="cardSingle">
                        <p class="mb-1 fw-normal text-dark fs-5">
                            {{ "split_card.modal.choose_products".trans() }}
                        </p>
                        <div
                            v-for="product in uniqueProducts(cardSingleDraftData.katalogProducts, cardSingle.katalogProducts)"
                            :key="product.id"
                            class="d-flex"
                        >
                            <input
                                class="form-check-input me-2 cursor-pointer flex-shrink-0"
                                type="checkbox"
                                :value="product.id"
                                :id="'split-card-product-' + product.id"
                                :checked="selectedProducts.includes(product.id)"
                                @change="handleProductSelected(product.id)"
                            />
                            <label :for="'split-card-product-' + product.id">
                                {{ product.Title }} - {{ product.id }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div>
                        <h5 v-if="cardSingle && selectedProducts.length == cardSingle.katalogProducts.length">
                            {{ "split_card.can_not_split_all_products.text".trans() }}
                        </h5>
                    </div>
                    <div>
                    <button
                        type="button"
                        class="btn btn-dark text-uppercase fw-bold me-1"
                        @click="confirmSplitCard"
                    >
                        {{ "split_card.modal.split".trans() }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-light text-uppercase fw-bold"
                        data-bs-dismiss="modal"
                    >
                        {{ "split_card.modal.close".trans() }}
                    </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, onUpdated, ref } from "vue";
import { useBacklogCardsStore } from "../../js/frontend/stores/backlogCards";
import { useCardSingleStore } from "../../js/frontend/stores/cardSingle";
import { splitCard } from "../../js/frontend/api/card";

const props = defineProps({
    backlogCards: {
        type: Array,
        required: true,
    },
    cardSingle: {
        type: Object,
        required: true,
    },
    cardSingleDraftData: {
        type: Object,
        required: true,
    }
});

const emit = defineEmits(['reloadPages'])

const select = ref(null);
const modal = ref(null);

const backlogCardsStore = useBacklogCardsStore();
const cardSingleStore = useCardSingleStore();

let selectedProducts = [];

let selectedCard = null;

const handleProductSelected = (id) => {
    if (selectedProducts.includes(id)) {
        const index = selectedProducts.indexOf(id);

        if (index !== -1) {
            selectedProducts.splice(index, 1);
        }
    } else {
        selectedProducts.push(id);
    }
};

const uniqueProducts = (currentProducts, draftProducts) => {
    const union = currentProducts.concat(draftProducts)

    const unionIds = union.map(
        (product) => product.Product.id
    )

    // remove duplicates
    const uniqueIds = [...new Set(unionIds)]

    let uniqueProducts = []
    
    uniqueIds.forEach(id => {
        const product = union.find(p => p.Product.id == id)

        if (product) uniqueProducts.push(product)
    })

    return uniqueProducts
}

async function confirmSplitCard() {
    if (selectedCard == null || selectedProducts.length < 1) {
        return;
    }

    try {
        const res = await splitCard(
            props.cardSingle.id.toString(),
            selectedCard,
            selectedProducts
        );
        await cardSingleStore.fetchData(props.cardSingle.id);
        await backlogCardsStore.fetchData(props.cardSingle.Catalog);

        emit('reloadPages');

        cardSingleStore.setNewSplitCard({
            id: res.cardId,
            isNewCard: selectedCard == "create-new-card" ? true : false,
        });

        selectedProducts = [];
        selectedCard = null;
        $(modal.value).modal("hide");
    } catch (error) {
        throw error;
    }
}

onUpdated(() => {
    if ($(select.value)[0].selectize) {
        $(select.value)[0].selectize.destroy();
    }

    let nonVirtualBacklogCards = [];

    if (props.backlogCards) {
        nonVirtualBacklogCards = props.backlogCards.filter(
            (obj) => !obj.isVirtual
        );
    }

    $(select.value).selectize({
        valueField: "id",
        labelField: "Label",
        searchField: ["Label"],
        diacritics: true,
        options: [
            {
                id: "create-new-card",
                Label: "split_card.options.new_card".trans(),
            },
            ...nonVirtualBacklogCards,
        ],
        onInitialize: function() {
            this.setValue('create-new-card')
        },
        onChange: function (selectedValue) {
            selectedCard = selectedValue;
        },
        onFocus: function () {
            this.removeOption(props.cardSingle.id);
        },
    });
});
</script>

<style lang="sass" scoped>
.mdi-triangle
    transform: rotate(180deg)
    top: 9px
    right: 18px
    z-index: 1
    pointer-events: none
</style>
