<template>
    <div class="modal fade" id="reorderPagesModal" tabindex="-1" aria-labelledby="reorderPagesModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" ref="modal">
        <div class="modal-dialog">
            <div class="modal-content p-3">
                <div class="modal-body text-black">
                    <h3 class="modal-title text-black mb-3 fw-bold" id="reorderPagesModalLabel">{{ 'reorder_pages.modal.title'.trans() }}</h3>

                    <draggable v-model="pages"
                        v-bind="dragOptions"
                        group="pages"
                        item-key="id"
                    >
                        <template #item="{element}">
                            <div class="page d-flex align-items-center justify-content-between border-bottom cursor-pointer">
                                <span class="fs-5 text-dark">{{ element.Position }} &nbsp; {{ element.Label }}</span>
                                <i class="mdi mdi-reorder-horizontal me-1 fs-3 text-dark"></i>
                            </div>
                        </template>
                    </draggable>

                </div>
                <div class="modal-footer">
                    <button @click="reorderCatalogPages"
                        type="button"
                        class="btn btn-dark text-uppercase fw-bold"
                        data-bs-dismiss="modal"
                    >
                        {{ 'reorder_pages.modal.confirm'.trans() }}
                    </button>
                    <button type="button" class="btn btn-light text-uppercase fw-bold" @click="hideModalAndUndoChanges">
                        {{ 'reorder_pages.modal.close'.trans() }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, watch } from 'vue'
    import { usePageListStore } from '../../js/frontend/stores/pageList'
    import { reorderPages } from '../../js/frontend/api/page'

    import draggable from '../components/draggable/vuedraggable.umd.min.js'

    const pageListStore = usePageListStore()

    const props = defineProps({
        pagesOrder: {
            type: Array,
            required: true
        },
        catalogId: {
            type: Number,
            required: true
        }
    })

    const dragOptions = {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
    }

    const modal = ref(null)
    const pages = ref([...props.pagesOrder])

    async function reorderCatalogPages() {
        let pageIds = pages.value.map(page => page.id)

        try {
            await reorderPages(pageIds)

            await pageListStore.fetchData(props.catalogId)

            pages.value = props.pagesOrder

        } catch (error) {
            throw error
        }
    }

    function hideModalAndUndoChanges() {
        pages.value = props.pagesOrder

        $(modal.value).modal('hide')
    }

    watch(props, (newData, oldData) => pages.value = newData.pagesOrder)
</script>

<style lang="sass" scoped>
    .page
        padding: 2px 0px
        margin-top: 2px
</style>