import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

import { fetchPages } from '../api/page'

export const usePageListStore = defineStore('pageList', () => {
    const data = ref([])
    const pairs = ref([])
    const loading = ref(true)

    const getData = computed(() => data)
    const getPairs = computed(() => pairs)
    const isLoading = computed(() => loading)

    async function fetchData(catalogId) {
        try {
            loading.value = true

            let response = await fetchPages(catalogId)

            data.value = response

            setPagePairs()

            loading.value = false

        } catch (error) {
            throw error
        }
    }

    function setPagePairs() {
        const pages = [...data.value]

        const lastElem = pages.slice(-1)[0]

        pages.unshift(lastElem)
        pages.pop()

        const pagePairs = pages.reduce(function (result, value, index, array) {
            if (index % 2 == 0) result.push(array.slice(index, index + 2))
            return result
        }, [])

        pairs.value = pagePairs
    }

    return {
        getData,
        getPairs,
        isLoading,
        fetchData
    }
})