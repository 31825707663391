<template>
    <div class="catalog-bottom-content">
        <div class="d-flex mt-3">
            <div class="page-number-container me-2 mt-auto mb-2">
                <span v-if="isLastPage"
                    class="bg-dark p-1 px-2 rounded-pill text-white fw-bold d-flex align-items-baseline"
                >
                    {{ pageData.Position }}
                    <i class="mdi mdi-lock text-white ms-1 fs-5"></i>
                </span>
                <span v-else
                    class="bg-dark p-1 px-2 rounded-pill text-white fw-bold"
                >
                    {{ pageData.Position }}
                </span>
            </div>
            <div class="page-name-container position-relative w-100">
                <label :for="'page-name' + pageData.Position" class="form-label text-dark fw-normal fs-5">{{ 'catalog.page.name_label'.trans() }}</label>
                <input :id="'page-name' + pageData.Position"
                    class="form-control"
                    type="text"
                    v-model="pageName"
                    @focus="nameInputFocus"
                    @blur="revertName($event)"
                    @keydown="() => showNameSubmitButton = true"
                    @keyup.enter="submitName"
                    :disabled="catalogStatus == 'finished'"
                    ref="pageNameInput"
                >
                <button v-if="showNameSubmitButton" @click="submitName"
                    class="name-submit-button btn btn-outline text-decoration-underline text-dark fw-bold position-absolute top-50 end-0">
                    {{ 'catalog.page.name_save'.trans() }}
                </button>
            </div>
        </div>
        <div class="d-flex align-items-center mt-2">
            <div class="me-2">
                <input :id="'web-only' + pageData.Position"
                    class="form-check-input"
                    type="checkbox"
                    v-model="isWebOnly"
                    @change="submitType"
                    :disabled="catalogStatus == 'finished'"
                >
                <label :for="'web-only' + pageData.Position" class="form-check-label cursor-pointer text-dark fw-normal fs-5 ms-1">{{ 'catalog.page.web_only'.trans() }}</label>
            </div>
            <div class="ms-auto me-2">
                <a class="d-flex align-items-center text-dark cursor-pointer"
                    data-bs-toggle="offcanvas"
                    :data-bs-target="isRight ? '#drawerPageCommentsRight' : '#drawerPageCommentsLeft'"
                    :aria-controls="isRight ? 'drawerPageCommentsRight' : 'drawerPageCommentsLeft'"
                >
                    <i class="mdi mdi-comment-text-multiple-outline me-1 fs-3"
                        :class="{ 'text-red': pageData.unreadPageCommentCount > 0 }"
                    ></i>
                    <span class="fs-5 text-decoration-underline cursor-pointer nowrap">
                        {{ 'catalog.page.comments'.trans() }} ({{ pageData.totalPageCommentCount }})
                    </span>
                </a>
            </div>
            <span class="vr mx-1 my-1 text-light-secondary">
            </span>
            <button @click="deletePageClick(pageData.id, pageData.Position)"
                class="bottom-action with-tooltip btn btn-outline p-0"
                data-bs-container=".text-light-secondary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="deleteButtonTooltip"
                :disabled="disableDelete"
            >
                <span data-bs-toggle="modal" data-bs-target="#deletePageModal">
                    <i class="mdi mdi-delete-outline mx-1 fs-3 text-dark"></i>
                </span>
            </button>

            <span class="vr mx-1 my-1 text-light-secondary">
            </span>

            <button @click="addPageClick(pageData.id, pageData.Position)"
                class="bottom-action with-tooltip btn btn-outline p-0"
                data-bs-container=".text-light-secondary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="'catalog.page.add_page'.trans()"
                :disabled="catalogStatus == 'finished'"
            >
                <span data-bs-toggle="modal" data-bs-target="#addPageModal">
                    <i class="mdi mdi-plus-circle-outline ms-1 fs-3 text-dark"></i>
                </span>
            </button>
        </div>
    </div>
</template>

<script setup>
    import { computed, ref } from 'vue'
    import { usePageListStore } from '../../js/frontend/stores/pageList'
    import { useCommentsStore } from '../../js/frontend/stores/comments'
    import { updateStats, addPage, deletePage } from '../../js/frontend/api/page'
    import { hotReload } from '../../js/frontend/helpers/hotReload'

    const props = defineProps({
        pageData: {
            type: Object,
            required: true
        },
        catalogId: {
            type: Number,
            required: true
        },
        catalogStatus: {
            type: String,
            required: true
        },
        isLastPage: {
            type: Boolean,
            required: true
        },
        isRight: {
            type: Boolean,
            required: true
        }
    })

    const emit = defineEmits(['pageAddClick', 'pageDeleteClick'])

    const pageListStore = usePageListStore()
    const commentsStore = useCommentsStore()

    const pageNameInput = ref(null)
    const pageName = ref(props.pageData.Label)
    const showNameSubmitButton = ref(false)
    const isWebOnly = ref(props.pageData.PageType == 'Web')

    const hasCards = computed(() => props.pageData.catalogCards.length > 0)
    const disableDelete = computed(() => (props.catalogStatus == 'finished') || props.isLastPage || hasCards.value)

    const startingName = ref(props.pageData.Label)

    const deleteButtonTooltip = computed(() => {
        if (props.isLastPage) {
            return 'catalog.page.remove_page.is_last'.trans()

        } else if (hasCards.value) {
            return 'catalog.page.remove_page.has_cards'.trans()

        } else {
            return 'catalog.page.remove_page'.trans()
        }
    })

    const commentsLength = computed(() => {
        if (props.isRight) return commentsStore.getActivePagesComments.value[1].length
        else return commentsStore.getActivePagesComments.value[0].length
    })

    function nameInputFocus() {
        showNameSubmitButton.value = true
    }

    async function submitName() {
        showNameSubmitButton.value = false

        try {
            await updateStats(props.pageData.id, pageName.value, isWebOnly.value ? 'Web' : 'Katalog')

            startingName.value = pageName.value

            pageListStore.fetchData(props.catalogId)

        } catch (error) {
            throw error
        }
    }

    function revertName(event) {
        if ($(event.relatedTarget).hasClass('name-submit-button')) return

        showNameSubmitButton.value = false
        pageName.value = startingName.value
    }

    async function submitType() {
        try {
            await updateStats(props.pageData.id, pageName.value, isWebOnly.value ? 'Web' : 'Katalog')

            hotReload('#numberOfPagesContainer')

            pageListStore.fetchData(props.catalogId)

        } catch (error) {
            throw error
        }
    }

    function addPageClick(pageId, pagePosition) {
        emit('pageAddClick', { pageId, pagePosition })
    }

    function deletePageClick(pageId, pagePosition) {
        emit('pageDeleteClick', { pageId, pagePosition })
    }

</script>

<style lang="sass" scoped>
    .btn:disabled
        pointer-events: all
</style>