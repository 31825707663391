<template>
    <div id="comments-tab" class="tab-pane mt-2">
        <div v-if="isLoading" class="spinner-wrapper d-flex">
            <span
                class="spinner-border spinner-border mx-auto mt-5"
                role="status"
            ></span>
        </div>
        <template v-else>
            <Comments
                :comments="comments"
                :id="id"
                :readOnly="isFinished"
                type="catalog_card"
                @commentsUpdated="updateComments"
            />
        </template>
    </div>
</template>

<script setup>
    import { onMounted, ref } from 'vue'
    import { useCommentsStore } from '../../../js/frontend/stores/comments'
    import Comments from '../Comments/Comments'

    const props = defineProps({
        id: {
            type: Number,
            required: true
        },
        isFinished: {
            type: Boolean,
            required: true
        }
    })

    const commentsStore = useCommentsStore()

    const comments = ref(commentsStore.getCardComments)
    const isLoading = ref(commentsStore.isLoading)

    onMounted(() => {
        const triggerEl = $('a[href="#comments-tab"]')

        triggerEl.on('shown.bs.tab', () => {
            updateComments()
        })
    })

    function updateComments() {
        try {
            commentsStore.fetchCardComments(props.id)

        } catch (error) {
            throw error
        }
    }
</script>
