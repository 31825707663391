<template>
	<div class="carousel-container mx-1 mb-5">
		<Splide ref="splideRef"
			:has-track="false"
			:options="{ type: 'slide', pagination: false, drag: false, arrows: false, speed: 0 }"
			@splide:click="moveSlider"
			@splide:active="sliderMoved"
		>
			<SplideTrack>
				<SplideSlide v-for="item in carouselItems" :key="item[0].id"
					:data-id="'page-carousel-' + item[0].id"
				>
					<p class="mb-1 d-flex card-drop-target" :data-page-id="item[0].id">
						{{ item[0].Position }} &nbsp; <span class="fw-bold">{{ item[0].Label }}</span>
						<i v-if="item[0].unreadPageCommentCount > 0 || item[0].unreadCardCommentCount > 0" class="mdi mdi-comment-text-multiple-outline ms-1"></i>
						<i v-if="item[0].CardStatuses.hasEditedCards && !isOverview" class="mdi mdi-alert ms-1"></i>
					</p>
					<p class="m-0 d-flex card-drop-target" :data-page-id="item[1].id" v-if="item.length > 1">
						{{ item[1].Position }} &nbsp; <span class="fw-bold">{{ item[1].Label }}</span>
						<i v-if="item[1].unreadPageCommentCount > 0 || item[1].unreadCardCommentCount > 0" class="mdi mdi-comment-text-multiple-outline ms-1"></i>
						<i v-if="item[1].CardStatuses.hasEditedCards && !isOverview" class="mdi mdi-alert ms-1"></i>
					</p>
				</SplideSlide>
			</SplideTrack>
			<div class="splide__arrows">
				<button type="button" class="splide__arrow splide__arrow--prev btn btn-outline text-dark p-0"><i class="fa fa-arrow-left-carousel"></i></button>
				<button type="button" class="splide__arrow splide__arrow--next text-dark p-0"><i class="fa fa-arrow-right-carousel"></i></button>
			</div>
		</Splide>
	</div>
</template>

<script setup>
	import { computed, ref, toRaw, watch } from 'vue'
	import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide'
	import '@splidejs/vue-splide/css'
	import { useSplideCarouselStore } from "../../js/frontend/stores/splideCarousel"

	const props = defineProps({
		carouselItems: {
			type: Array,
			required: true
		},
		activeCardId: {
			type: Number,
			required: false
		},
		isOverview: {
			type: Boolean,
			required: true
		}
	})

	const splideStore = useSplideCarouselStore()
	const emit = defineEmits(['select'])

	const previousActiveState = ref('')
	const activeItems = ref('')
	const splideRef = ref()
	const stateRemainedSame = computed(() => activeItems.value.toString() == previousActiveState.value.toString())

	watch(
		splideStore,
		newIndex => {
			splideRef.value.splide.go(newIndex.getCurrentSlideIndex.value)
		}
    )

	function moveSlider(slider, e) {
		slider.go(e.index)
	}

	function sliderMoved(slider, e) {
		activeItems.value = props.carouselItems[e.index].map(item => item.id)

		if (stateRemainedSame.value) return

		previousActiveState.value = activeItems.value

		emit('select', activeItems.value)
	}
</script>

<style lang="sass" scoped>
	.splide__arrow
		background: initial

		&:disabled
			pointer-events: none

		&--prev
			left: -3em

		&--next
			right: -3em
		
	.splide__slide
		display: flex
		flex-flow: column
		background-color: var(--ct-light)
		color: black
		border-radius: 0.3rem
		padding: 0.75rem
		margin: 0 0.375rem 0.375rem 0.375rem
		cursor: pointer
		font-size: 15px
		width: calc( 100% / 8 ) !important

		&.is-active
			background-color: #F00
			color: white

	.mdi-alert
		color: red

	.mdi-comment-text-multiple-outline
		color: red
</style>