<template>
    <div
        v-if="showBoxChangesCard"
        class="box-changes-container bg-soft-secondary rounded-2 p-3 mt-3"
    >
        <div class="d-flex justify-content-between">
            <h3 class="fw-bold mt-0">
                {{ "box.changes.title".trans() }}
            </h3>
            <button class="btn btn-outline p-0">
                <i
                    class="mdi mdi-close text-black fs-3"
                    @click="closeBoxChangesCard"
                ></i>
            </button>
        </div>
        <div class="text-dark">
            <p class="mb-1" v-if="addedProducts > 0">{{ "box.changes.added_products".trans() }} {{ addedProducts }}</p>
            <p class="mb-1" v-if="removedProducts > 0">{{ "box.changes.removed_products".trans() }} {{ removedProducts }}</p>
            <p class="mb-1" v-if="changedProducts > 0">{{ "box.changes.new_data".trans() }} {{ changedProducts }}</p>
        </div>
    </div>
</template>

<script setup>
    import { ref } from "vue";

    const props = defineProps({
        addedProducts: {
            type: Number,
            required: true
        },
        removedProducts: {
            type: Number,
            required: true
        },
        changedProducts: {
            type: Number,
            required: true
        }
    })

    const showBoxChangesCard = ref(true);

    const closeBoxChangesCard = () => {
        showBoxChangesCard.value = false;
    };
</script>
