<template>
    <div
        class="modal fade"
        id="tvModal"
        tabindex="-1"
        aria-labelledby="tvModalLabel"
        aria-hidden="true"
        ref="modal"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body text-black">
                    <h3
                        class="modal-title text-black mb-3"
                        id="tvModalLabel"
                    >
                        {{ "tv.modal.title".trans() }}
                    </h3>
                    <h5>
                        {{ "tv.modal.text".trans() }}
                    </h5>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-light text-uppercase fw-bold"
                        data-bs-dismiss="modal"
                    >
                        {{ "tv.modal.close".trans() }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="sass" scoped>

</style>