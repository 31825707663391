<template>
    <div class="modal fade" id="saveAndPublishModal" tabindex="-1" aria-labelledby="saveAndPublishModalLabel" aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body text-black">
                    <h3 class="modal-title text-black mb-3" id="saveAndPublishModalLabel">{{ 'save_and_publish.modal.title'.trans() }}</h3>
                    <h5 class="mb-2">
                        {{ 'save_and_publish.modal.text'.trans() }}
                    </h5>
                    <h5>
                        {{ 'save_and_publish.modal.disclaimer'.trans() }}
                    </h5>
                </div>
                <div class="modal-footer">
                    <button @click="saveAndPublishConfirm"
                        type="button"
                        class="btn btn-dark text-uppercase fw-bold"
                        data-bs-dismiss="modal"
                    >
                        {{ 'save_and_publish.modal.confirm'.trans() }}
                    </button>
                    <button type="button" class="btn btn-light text-uppercase fw-bold" data-bs-dismiss="modal">
                        {{ 'save_and_publish.modal.close'.trans() }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    const emit = defineEmits(['saveAndPublish'])

    function saveAndPublishConfirm() {
        emit('saveAndPublish')
    }
</script>