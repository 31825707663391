import axios from 'axios'
import { showErrorToast } from '../helpers/generalErrorHandler'

async function updateStats(catalogId, transitionName, newPages, deadline) {
    if (!catalogId || !deadline) return

    try {
        await axios.post(`/api/catalog/update-details`, {
            'catalogId': catalogId,
            'transitionName': transitionName,
            'newPages': newPages,
            'deadline': deadline
        })

    } catch (error) {
        showErrorToast(error)
        throw error
    }
}

async function updateUsers(catalogId, memberIds) {
    if (!catalogId || !memberIds) return

    try {
        await axios.post(`/api/catalog/update-members`, {
            'catalog_id': catalogId,
            'member_ids': memberIds
        })

    } catch (error) {
        showErrorToast(error)
        throw error
    }
}

async function fetchTopics(catalogId) {
    if (!catalogId) return

    try {
        const response = await axios.post(`/api/catalog/topics`, {
            'catalogId': catalogId.toString(),
        })

        const data = response.data.map(item => {
            return {
                'id': item.id,
                'text': item.label
            }
        })

        return data || []

    } catch (error) {
        showErrorToast(error)
        throw error
    }
}

async function fetchDepartmentMasks(catalogId) {
    if (!catalogId) return

    try {
        const response = await axios.post(`/api/catalog/department-masks`, {
            'catalogId': catalogId.toString(),
        })

        const data = response.data.map(item => {
            return {
                'id': item.id,
                'text': item.label
            }
        })

        return data || []

    } catch (error) {
        showErrorToast(error)
        throw error
    }
}

async function fetchRobniProgram(catalogId) {
    if (!catalogId) return

    try {
        const response = await axios.post(`/api/catalog/robni-program`, {
            'catalogId': catalogId.toString(),
        })

        const data = response.data.map(item => {
            return {
                'id': item.id,
                'text': item.label
            }
        })

        return data || []

    } catch (error) {
        showErrorToast(error)
        throw error
    }
}

async function fetchOverviewData(catalogId) {
    if (!catalogId) return

    try {
        const response = await axios.post('/api/catalog/overview', {
            'catalogId': catalogId
        })

        const data = {
            'draftCatalogCards': response.data.draftCatalogCards || 0,
            'publishedCatalogCards': response.data.publishedCatalogCards || 0,
            'totalCatalogCards': response.data.totalCatalogCards || 0,
            'totalCatalogProducts': response.data.totalCatalogProducts || 0
        }

        return data

    } catch (error) {
        showErrorToast(error)
        return {}
    }
}

async function download(type, catalogId, cardIds = []) {
    if (!type || !catalogId) return

    let xmlResponse = ''

    try {
        if (type == 'articlesFinalNoPrice') {
            xmlResponse = await axios({
                method: 'post',
                url: '/api/catalog/products-export',
                data: {
                    'catalogId': catalogId,
                    'price': false
                },
                responseType: 'blob'
            })
        } else if (type == 'articlesFinal') {
            xmlResponse = await axios({
                method: 'post',
                url: '/api/catalog/products-export',
                data: {
                    'catalogId': catalogId,
                    'price': true
                },
                responseType: 'blob'
            })
        } else if (type == 'tv') {
            xmlResponse = {}

            await axios.post('/api/catalog/tv-promotion-export', {
                'catalogId': catalogId,
                'catalogCards': cardIds
            })

            $('#tvModal').modal('show')

        } else if (type == 'pagesAndCards') {
            xmlResponse = await axios({
                method: 'post',
                url: '/api/catalog/pages-cards-export',
                data: {
                    'catalogId': catalogId
                },
                responseType: 'blob'
            })
        } else if (type == 'articlesWeb') {
            xmlResponse = await axios({
                method: 'post',
                url: '/api/catalog/web-only-export',
                data: {
                    'catalogId': catalogId
                },
                responseType: 'blob'
            })
        } else if (type == 'pdfPages') {
            xmlResponse = await axios({
                method: 'get',
                url: `/api/catalog/${catalogId}/export-page-pdf`,
                responseType: 'blob'
            })
        } else if (type == 'pdfOverview') {
            xmlResponse = await axios({
                method: 'get',
                url: `/api/catalog/${catalogId}/export-preview-pdf`,
                responseType: 'blob'
            })
        }

        if (xmlResponse.data) {
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(xmlResponse.data);

            if (xmlResponse.headers['content-disposition'] && xmlResponse.headers['content-disposition'].split('filename=').length > 1) {
                a.download = xmlResponse.headers['content-disposition'].split('filename=')[1];
            } else {
                a.download = 'plodine-export.xlsx';
            }

            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }

    } catch (error) {
        showErrorToast(error)
        throw error
    }
}

export { updateStats, updateUsers, fetchTopics, fetchDepartmentMasks, fetchRobniProgram, fetchOverviewData, download }