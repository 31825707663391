<template>
    <div class="drawer-page-comments-container">
        <div :id="isRight ? 'drawerPageCommentsRight' : 'drawerPageCommentsLeft'"
            class="offcanvas offcanvas-end"
            tabindex="-1"
            :aria-labelledby="isRight ? 'drawerPageCommentsLabelRight' : 'drawerPageCommentsLabelLeft'"
            ref="root"
        >
            <div v-if="isLoading" class="spinner-wrapper d-flex">
                <span
                    class="spinner-border spinner-border mx-auto mt-5"
                    role="status"
                ></span>
            </div>
            <template v-else>
                <div class="offcanvas-header drawer-page-comments-header border-bottom mx-4 px-0 pb-2">
                    <h2 :id="isRight ? 'drawerPageCommentsLabelRight' : 'drawerPageCommentsLabelLeft'"
                        class="offcanvas-title fw-bold text-black mt-1"
                    >
                        {{ pageName }}
                    </h2>
                    <button
                        class="btn-close text-reset"
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="offcanvas-body drawer-page-comments-body p-4">
                    <Comments
                        :comments="comments"
                        :id="id"
                        :readOnly="catalogStatus == 'finished'"
                        :activePagesIds="activePagesIds"
                        type="catalog_page"
                        @commentsUpdated="updateComments"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
    import { computed, onMounted, ref } from 'vue'
    import Comments from '../Comments/Comments'

    import { useCommentsStore } from "../../../js/frontend/stores/comments";

    const commentsStore = useCommentsStore()

    const props = defineProps({
        id: {
            type: Number,
            required: true
        },
        pageName: {
            type: String,
            required: true
        },
        isRight: {
            type: Boolean,
            required: true
        },
        activePagesIds: {
            type: Array,
            required: true
        },
        catalogStatus: {
            type: String,
            required: true
        }
    })

    const emit = defineEmits(['commentsDrawerClose'])

    const root = ref('')
    const isLoading = ref(commentsStore.isLoading)

    const comments = computed(() => {
        if (props.isRight) return commentsStore.getActivePagesComments.value[1]
        else return commentsStore.getActivePagesComments.value[0]
    })

    onMounted(() => {
        $(root.value).on('show.bs.offcanvas', function() {
            commentsStore.fetchActivePagesComments(props.activePagesIds, props.isRight ? 'right' : 'left')
        })

        $(root.value).on('hide.bs.offcanvas', function() {
            emit('commentsDrawerClose')
        })
    })

    function updateComments() {
        try {
            commentsStore.fetchActivePagesComments(props.activePagesIds, props.isRight ? 'right' : 'left')

        } catch (error) {
            throw error
        }
    }
</script>

<style lang="sass" scoped>

</style>
