import { defineStore } from "pinia";
import { ref, computed } from "vue";

import { fetchOverviewData } from "../api/catalog";

export const useOverviewStore = defineStore("overview", () => {
    const overviewData = ref(null)
    const selectedCards = ref([])

    const getOverviewData = computed(() => overviewData)
    const getSelectedCards = computed(() => selectedCards)

    function updateSelectedCards(id) {
        if (selectedCards.value.includes(id)) {
            const index = selectedCards.value.indexOf(id);

            if (index !== -1) {
                selectedCards.value.splice(index, 1);
            }
        } else {
            selectedCards.value.push(id);
        }

        localStorage.setItem('selectedTVCards', JSON.stringify(selectedCards.value))
    }

    async function fetchCatalogOverviewData(catalogId) {
        if (!catalogId) return

        try {
            const response = await fetchOverviewData(catalogId)

            overviewData.value = response
            
        } catch (error) {
            throw error
        }
    }

    return {
        getOverviewData,
        fetchCatalogOverviewData,
        getSelectedCards,
        updateSelectedCards,
    }
})