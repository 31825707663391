import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

import { fetchResults } from '../api/headerSearch'

export const useSearchResultsStore = defineStore('searchResults', () => {
    const results = ref(null)
    const loading = ref(true)

    const getResults = computed(() => results)
    const isLoading = computed(() => loading)

    async function fetchSearchResults(catalogId, searchTerm) {
        try {
            loading.value = true

            const response = await fetchResults(catalogId, searchTerm)

            results.value = response

            loading.value = false

        } catch (error) {
            loading.value = false
            throw error
        }
    }

    return {
        getResults,
        isLoading,
        fetchSearchResults
    }
})
