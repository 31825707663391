import { defineStore } from "pinia";
import { ref, computed } from "vue";

import { fetchCardCommentsData, fetchActivePageCommentsData } from "../api/comments";

export const useCommentsStore = defineStore("comments", () => {
    const cardComments = ref([])
    const activePagesComments = ref([[],[]])
    const commentToBeDeleted = ref(null)
    const loading = ref(true)

    const getCardComments = computed(() => cardComments)
    const getActivePagesComments = computed(() => activePagesComments)
    const getCommentToBeDeleted = computed(() => commentToBeDeleted)
    const isLoading = computed(() => loading)

    async function fetchCardComments(id) {
        if (!id) return

        try {
            loading.value = true

            const response = await fetchCardCommentsData(id)

            cardComments.value = response

            loading.value = false
            
        } catch (error) {
            loading.value = false
            throw error
        }
    }

    async function fetchActivePagesComments(pageIdsArray, page = 'both') {
        if (!pageIdsArray) return

        try {
            loading.value = true

            if (pageIdsArray.length > 1) {
                let response = []

                if (page == 'left') {
                    response = await Promise.all([fetchActivePageCommentsData(pageIdsArray[0])])
                    activePagesComments.value = [response[0], []]
                } else if (page == 'right') {
                    response = await Promise.all([fetchActivePageCommentsData(pageIdsArray[1])])
                    activePagesComments.value = [[], response[0]]
                } else {
                    response = await Promise.all([fetchActivePageCommentsData(pageIdsArray[0]), fetchActivePageCommentsData(pageIdsArray[1])])
                    activePagesComments.value = [response[0], response[1]]
                }

            } else {
                let response = await fetchActivePageCommentsData(pageIdsArray[0])
                activePagesComments.value = [response]
            }

            loading.value = false

        } catch (error) {
            loading.value = false
            throw error
        }
    }

    function setCommentToBeDeleted(commentId, objectType, objectId, activePagesIds) {
        commentToBeDeleted.value = {
            'commentId' : commentId,
            'objectType': objectType,
            'objectId': objectId,
            'activePagesIds': activePagesIds
        }
    }

    return {
        getCardComments,
        getActivePagesComments,
        getCommentToBeDeleted,
        setCommentToBeDeleted,
        fetchCardComments,
        fetchActivePagesComments,
        isLoading
    }
})