import { defineStore } from "pinia";
import { ref, computed } from "vue";

import { fetchSingleCardData, fetchBrands } from "../api/card";
import CardStatusEnum from "../helpers/card-status-enum";

export const useCardSingleStore = defineStore("cardSingle", () => {
    const draftData = ref(null);
    const currentData = ref(null);
    const brands = ref([])
    const loading = ref(true);
    const lockedCardFor = ref(null);
    const newSplitCard = ref(null);

    const selectedProducts = ref([]);

    const originalProductsData = ref([])
    const originalCardData = ref({
        'Brand': { 'label': null, 'value': null },
        'Label': null,
        'Description': null,
        'DesignerNote': null,
        'displayMeasureValue': null,
        'PriceFrom': null,
        'LowestPrice': null,
        'Price': null,
        'Percentage': null,
        'isWebOnly': null,
        'installment': null,
        'MarketingDiscount': null,
        'katalogProductImages': null
    })

    const valuesForPriceDropdown = ref(null);

    const addedProducts = ref([]);
    const removedProducts = ref([]);
    const changedProducts = ref([]);

    const cardLevelSelectedImages = ref([])

    const getDraftData = computed(() => draftData);
    const getCurrentData = computed(() => currentData);
    const isLoading = computed(() => loading);
    const getSelectedProducts = computed(() => selectedProducts);
    const getNewSplitCard = computed(() => newSplitCard);
    const getBrands = computed(() => brands)
    const getCardLevelSelectedImages = computed(() => {
        if (isSavedAsDraft()) {
            return draftData.value ? draftData.value.katalogProductImages : [];
        }

        return currentData.value ? currentData.value.katalogProductImages : [];
    });

    const getLockedCardFor = computed(() => lockedCardFor);
    const getValuesForPriceDropdown = computed(() => valuesForPriceDropdown);
    const getOriginalCardData = computed(() => originalCardData)

    const getAllProductIds = computed(() => {
        let ids = [];

        if (currentData.value) {
            ids = currentData.value.katalogProducts.map(
                (product) => product.Product.id
            );
        }

        if (draftData.value) {
            ids = ids.concat(
                draftData.value.katalogProducts.map(
                    (product) => product.Product.id
                )
            );
        }

        // remove duplicates
        ids = [...new Set(ids)]

        return ids;
    });

    const getAddedProducts = computed(() => addedProducts);
    const getRemovedProducts = computed(() => removedProducts);
    const getChangedProducts = computed(() => changedProducts);

    async function fetchData(cardId, force = false) {
        if (!cardId) return;

        loading.value = true;

        if (lockedCardFor.value != null) {
            lockedCardFor.value = null;
        }

        try {
            const response = await fetchSingleCardData(cardId, force);

            if (response == "locked-card") {
                lockedCardFor.value = cardId;
                return "locked";
            }

            draftData.value = response.draft;
            currentData.value = response.current;

            setOriginalProductsData()
            setOriginalCardData()

            setProductFlags()

            if (currentData.value) {
                setValuesForPriceDropdown(draftData.value.katalogProducts);
            }

            setSelectedProducts()

            loading.value = false;
        } catch (error) {
            loading.value = false;
            throw error;
        }
    }

    async function fetchCardBrands(cardId) {
        try {
            const response = await fetchBrands(cardId)
            brands.value = response

        } catch (error) {
            throw error
        }
    }

    /**
     * It tells you if the card is saved as draft or it just showing arrived changes
     *
     * @see `../helpers/card-status-enum`
     *
     * @returns {string} - 'draft' or 'saved-as-draft'
     */
    function getDraftStatus() {
        return draftData.value.Status;
    }

    function getCurrentProduct(productId, deepCopy = false) {
        let data = currentData.value;

        if (deepCopy) data = { ...currentData.value };

        return data.katalogProducts.filter(
            (product) => product.Product.id == productId
        )[0];
    }

    function getDraftProduct(productId, deepCopy = false) {
        let data = draftData.value;

        if (deepCopy) data = { ...draftData.value };

        return data.katalogProducts.filter(
            (product) => product.Product.id == productId
        )[0];
    }

    function getOriginalProduct(productId) {
        return originalProductsData.value.filter(product => product.Product.id == productId)[0]
    }

    function updateSelectedProducts(id) {
        if (selectedProducts.value.includes(id)) {
            const index = selectedProducts.value.indexOf(id);

            if (index !== -1) {
                selectedProducts.value.splice(index, 1);
            }
        } else {
            selectedProducts.value.push(id);
        }
    }

    function setNewSplitCard(splitCardInfo) {
        if (splitCardInfo == null) {
            newSplitCard.value = null;
        } else {
            newSplitCard.value = { ...splitCardInfo };
        }
    }

    function setValuesForPriceDropdown(products) {
        let prices = [];
        products.forEach((product) => {
            if (product.LowestPrice) {
                prices.push({
                    text: `${product.LowestPrice} EUR`,
                    value: product.LowestPrice,
                });
            } else {
                prices.push({
                    text: `${product.Price} EUR`,
                    value: product.Price,
                });
            }
        });
        prices = [...prices.sort((a, b) => a.value - b.value)];

        valuesForPriceDropdown.value = [...prices];
    }

    function selectAllProducts() {
        selectedProducts.value = currentData.value.katalogProducts.map(
            (product) => product.Product.id
        );
    }

    function unselectAllProducts() {
        selectedProducts.value = [];
    }

    /**
     * It tells you if the card is saved as draft or it just showing arrived changes
     *
     * @returns {boolean}
     */
    function isSavedAsDraft() {
        return getDraftStatus() == CardStatusEnum.SAVED_AS_DRAFT;
    }

    /**
     * This method dynamicaly determines what is source of true, depending on card state
     *
     * Because, if card is saved as draft, than draft is source of true, otherwise current data will be used
     * @returns
     */
    function getTrueData() {
        if (isSavedAsDraft()) {
            return draftData.value
        }

        return currentData.value;
    }

    function setSelectedProducts() {
        selectedProducts.value = []

        let publishedProducts = (currentData.value || {}).katalogProducts || [];
        let draftroducts = (draftData.value || {}).katalogProducts || [];

        // Depending on draft node state, we looking for selected status on original node or on draft node;
        // Because, if card is saved as draft, than draft has higher priority for selection
        let products = isSavedAsDraft() ? draftroducts : publishedProducts;
        selectedProducts.value = products.filter(a => a.selected).map(a => a.Product.id);
    }

    function setOriginalProductsData() {
        const allProducts = getAllProductIds.value;

        const originalData = allProducts.map((productId) => {
            let originalProductData = {};

            if (currentData.value && getCurrentProduct(productId))
                originalProductData = getCurrentProduct(productId, true);
            if (draftData.value && getDraftProduct(productId))
                originalProductData = getDraftProduct(productId, true);

            return originalProductData;
        });

        originalProductsData.value = originalData;
    }

    function setOriginalCardData() {
        originalCardData.value.Brand = currentData.value.Brand
        originalCardData.value.Label = currentData.value.Label
        originalCardData.value.Description = currentData.value.Description
        originalCardData.value.DesignerNote = currentData.value.DesignerNote

        originalCardData.value.displayMeasureValue = currentData.value.displayMeasureValue

        originalCardData.value.PriceFrom = currentData.value.PriceFrom
        originalCardData.value.LowestPrice = currentData.value.LowestPrice
        originalCardData.value.Price = currentData.value.Price
        originalCardData.value.Percentage = currentData.value.Percentage

        originalCardData.value.isWebOnly = currentData.value.isWebOnly
        originalCardData.value.installment = currentData.value.installment
        originalCardData.value.MarketingDiscount = currentData.value.MarketingDiscount

        originalCardData.value.katalogProductImages = currentData.value.katalogProductImages
    }

    function setProductFlags() {
        const allProducts = getAllProductIds.value;

        removedProducts.value = [];
        addedProducts.value = [];
        changedProducts.value = [];

        allProducts.map((productId) => {
            if (getCurrentProduct(productId) && !getDraftProduct(productId)) {
                removedProducts.value.push(productId);
            } else if (
                !getCurrentProduct(productId) &&
                getDraftProduct(productId)
            ) {
                addedProducts.value.push(productId);
            } else if (
                getCurrentProduct(productId) &&
                getDraftProduct(productId)
            ) {
                const currentData = getCurrentProduct(productId);
                const draftData = getDraftProduct(productId);

                if (
                    currentData.ErpTextInCatalog !=
                        draftData.ErpTextInCatalog ||
                    currentData.MarketingNote != draftData.MarketingNote ||
                    currentData.LowestPrice != draftData.LowestPrice ||
                    currentData.Price != draftData.Price ||
                    currentData.UnitPrice != draftData.UnitPrice ||
                    currentData.Percentage != draftData.Percentage
                ) {
                    changedProducts.value.push(productId);
                }
            }
        });
    }

    return {
        getDraftData,
        getCurrentData,

        // This is new approach, true data is source of true depending on card status
        getTrueData,

        getDraftStatus,
        isSavedAsDraft,
        isLoading,
        getNewSplitCard,
        getSelectedProducts,
        getAllProductIds,
        fetchData,
        fetchCardBrands,
        getCurrentProduct,
        getDraftProduct,
        updateSelectedProducts,
        selectAllProducts,
        unselectAllProducts,
        getOriginalProduct,
        getOriginalCardData,
        getCardLevelSelectedImages,
        getAddedProducts,
        getRemovedProducts,
        getChangedProducts,
        getBrands,
        setNewSplitCard,
        getLockedCardFor,
        getValuesForPriceDropdown,
    }
})
