export function showErrorToast(error) {
    if (error.response.status == 423) return

    let errorText = ''
    if (error.response && error.response.message) {
        errorText = error.response.message

    } else if (error.response && error.response.error) {
        errorText = error.response.error

    } else if (error.response && error.response.data && error.response.data.detail) {
        errorText = error.response.data.detail
    } else if (error.response && error.response.data && error.response.data.error) {
        errorText = error.response.data.error
    }

    if (errorText) {
        $('#generalToast').find('.text-general').addClass('d-none')
        $('#generalToast').find('.text-specific').removeClass('d-none')
        $('#generalToast').find('.text-specific').html(errorText)

    } else {
        $('#generalToast').find('.text-general').removeClass('d-none')
        $('#generalToast').find('.text-specific').addClass('d-none')
        $('#generalToast').find('.text-specific').html('')
    }

    $('#generalToast').toast('show')
}