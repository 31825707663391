<template>
    <div
        class="modal fade"
        id="createVirtualModal"
        tabindex="-1"
        aria-labelledby="createVirtualModalLabel"
        aria-hidden="true"
        ref="modal"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body text-black">
                    <h3
                        class="modal-title text-black mb-3"
                        id="createVirtualModalLabel"
                    >
                        {{ "create_virtual_card.modal.title".trans() }}
                    </h3>
                    <h5>
                        {{ "create_virtual_card.modal.text".trans() }}
                    </h5>
                </div>
                <div class="modal-footer">
                    <button
                        @click="confirmCardDelete"
                        type="button"
                        class="btn btn-dark text-uppercase fw-bold"
                        data-bs-dismiss="modal"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#drawerBoxEdit"
                        aria-controls="drawerBoxEdit"
                    >
                        {{ "create_virtual_card.modal.create_copy".trans() }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-light text-uppercase fw-bold"
                        data-bs-dismiss="modal"
                    >
                        {{ "create_virtual_card.modal.close".trans() }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useBacklogCardsStore } from "../../js/frontend/stores/backlogCards";
import { useCardSingleStore } from "../../js/frontend/stores/cardSingle";

import { createVirtualCard } from "../../js/frontend/api/card";

const modal = ref(null);

const backlogCardsStore = useBacklogCardsStore();
const cardSingleStore = useCardSingleStore();

const data = ref(cardSingleStore.getCurrentData);

async function confirmCardDelete() {
    $(modal.value).modal("hide");
    backlogCardsStore.setLoading(true);

    try {
        await createVirtualCard(data.value.id.toString());
        await backlogCardsStore.fetchData(data.value.Catalog);
    } catch (error) {
        throw error;
    }
}
</script>
