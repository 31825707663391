<template>
    <div
        class="modal fade"
        id="deleteCommentModal"
        tabindex="-1"
        aria-labelledby="deleteCommentLabel"
        aria-hidden="true"
        ref="modal"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body text-black">
                    <h3
                        class="modal-title text-black mb-3"
                        id="deleteCommentLabel"
                    >
                        {{ "comment.delete_modal.title".trans() }}
                    </h3>
                    <h5>
                        {{ "comment.delete_modal.text".trans() }}
                    </h5>
                </div>
                <div class="modal-footer">
                    <button @click="confirmDeleteComment"
                        type="button"
                        class="btn btn-dark text-uppercase fw-bold"
                        data-bs-dismiss="modal"
                    >
                        {{ "comment.delete_modal.confirm".trans() }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-light text-uppercase fw-bold"
                        data-bs-dismiss="modal"
                    >
                        {{ "comment.delete_modal.close".trans() }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useCommentsStore } from '../../js/frontend/stores/comments'
    import { deleteComment } from '../../js/frontend/api/comments'

    const commentsStore = useCommentsStore()

    async function confirmDeleteComment() {
        const comment = commentsStore.getCommentToBeDeleted

        await deleteComment(comment.value['commentId'])

        if (comment.value['objectType'] == 'catalog_card') {
            commentsStore.fetchCardComments(comment.value['objectId'])

        } else {
            commentsStore.fetchActivePagesComments(comment.value['activePagesIds'])
        }
    }
</script>

<style lang="sass" scoped>

</style>