<template>
    <div class="side-container w-28" ref="root">
        <BacklogActions :catalogId="catalogId" :isFinished="isFinished" />

        <template v-if="catalogStatus != 'finished'">
            <div class="info-container d-flex align-items-center mt-2">
                <div class="text-black fs-5 ms-auto">
                    {{ "catalog.box.number".trans() }}
                    {{ cardsCount }}
                </div>
                <span class="vr mx-2 my-1 text-light-secondary"></span>
                <div class="text-black fs-5">
                    {{ "catalog.box.updated".trans() }} {{ timeStamp }}
                </div>
                <span class="vr mx-2 my-1 text-light-secondary"></span>
                <button
                    class="btn btn-outline text-decoration-underline p-0 text-black fs-5 me-auto"
                    @click="refreshData"
                >
                    {{ "catalog.box.update_now".trans() }}
                </button>
            </div>

            <BacklogCards
                :cards="backlogCards"
                :catalogId="catalogId"
                :activePagesIds="activePagesIds"
                :isLoading="isBacklogCardsLoading"
                ref="backlogCardsComponent"
            />
        </template>
    </div>
</template>

<script setup>
    import { computed, ref, onMounted } from "vue";

    import BacklogActions from "./BacklogActions";
    import BacklogCards from "./BacklogCards";
    import { useBacklogCardsStore } from "../../../js/frontend/stores/backlogCards";

    const props = defineProps({
        catalogId: {
            type: Number,
            required: true,
        },
        catalogStatus: {
            type: String,
            required: true,
        },
        activePagesIds: {
            type: Array,
            required: true,
        },
        isFinished: {
            type: Boolean,
            required: true
        }
    });

    const backlogCardsStore = useBacklogCardsStore();

    backlogCardsStore.fetchData(props.catalogId);

    const root = ref(null);
    const backlogCardsComponent = ref(null);
    const backlogCards = ref(backlogCardsStore.getData);
    const timeStamp = ref(backlogCardsStore.getTime);
    const isBacklogCardsLoading = ref(backlogCardsStore.isLoading);
    const filtersPayload = ref(backlogCardsStore.getPayload);

    const fetchPayload = ref({});

    const cardsCount = computed(() => (backlogCards.value || []).length);

    function refreshData() {
        backlogCardsStore.fetchData(props.catalogId, filtersPayload.value);
    }
</script>

<style lang="sass" scoped>
    .spinner-wrapper
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center

        .spinner-border
            width: 3.5rem
            height: 3.5rem
</style>
