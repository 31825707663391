import { download } from "../api/catalog"

class DownloadCatalog {
    constructor(DomElem = []) {
        if (DomElem.length == 0) {
            return false
        }

        this.DomElem = DomElem
        this.select = this.DomElem.find('#downloadSelect')
        this.submitButton = this.DomElem.find('.select-download-button')

        this.initSelect()
        this.initSubmit()
    }

    initSelect() {
        const self = this

        localStorage.setItem('selectedTVCards', null)

        this.select.selectize({
            onChange: function(val) {
                if (val) {
                    self.submitButton.prop('disabled', false)

                    const selectedTVcards = JSON.parse(localStorage.getItem('selectedTVCards')) || []

                    if (val == 'tv' && selectedTVcards.length == 0) {
                        self.submitButton.prop('disabled', true)
                    }

                } else {
                    self.submitButton.prop('disabled', true)
                }
            }
        })

        this.DomElem.on('show.bs.offcanvas', () => this.select[0].selectize.clear())
    }

    initSubmit() {
        const self = this

        this.submitButton.on('click', async function() {
            const selectedTVcards = JSON.parse(localStorage.getItem('selectedTVCards')) || []

            await download(self.select.val(), self.DomElem.data('catalogId'), selectedTVcards)
        })
    }
}

export default DownloadCatalog