<template>
    <div class="weight-and-units-container">
        <div v-for="(measure, index) in packageGroups" :key="index"
            class="weight-and-units-row d-flex mb-2"
        >
            <div class="me-2">
                <label class="form-label text-dark fw-normal fs-5">{{ "box.unit_data.from_unit".trans()}}</label>
                <input class="form-control text-dark width-less"
                    type="text"
                    :value="measure.izjm.split(' ')[1]"
                    disabled
                />
            </div>
            <span class="vr me-2 text-light"></span>
            <div class="me-1">
                <label class="form-label text-dark fw-normal fs-5">{{ "box.unit_data.quantity".trans() }}</label>
                <input class="form-control text-dark"
                    type="text"
                    :value="measure.size[0].split(' ')[0]"
                    disabled
                />
            </div>
            <div class="me-1" :class="{ 'me-2': !measure.size[1] }">
                <label class="form-label text-dark fw-normal fs-5">{{ "box.unit_data.to_unit".trans() }}</label>
                <input class="form-control text-dark width-less"
                    type="text"
                    :value="measure.size[0].split(' ')[1]"
                    disabled
                />
            </div>
            <template v-if="measure.size[1]">
                <div class="me-1">
                    <label class="form-label">&nbsp;</label>
                    <p class="mb-0 text-black" style="margin-top: 0.5rem">-</p>
                </div>
                <div class="me-1">
                    <label class="form-label text-dark fw-normal fs-5">{{ "box.unit_data.quantity".trans() }}</label>
                    <input class="form-control text-dark"
                        type="text"
                        :value="measure.size[1].split(' ')[0]"
                        disabled
                    />
                </div>
                <div class="me-2">
                    <label class="form-label text-dark fw-normal fs-5">{{ "box.unit_data.to_unit".trans() }}</label>
                    <input class="form-control text-dark width-less"
                        type="text"
                        :value="measure.size[1].split(' ')[1]"
                        disabled
                    />
                </div>
            </template>
            <span class="vr me-2 text-light"></span>
            <div class="me-1">
                <label class="form-label text-dark fw-normal fs-5">{{ "box.unit_data.quantity".trans() }}</label>
                <input class="form-control text-dark width-less"
                    type="text"
                    value="1"
                    disabled
                />
            </div>
            <div class="me-1">
                <label class="form-label text-dark fw-normal fs-5">{{ "box.unit_data.to_unit".trans() }}</label>
                <input class="form-control text-dark width-less"
                    type="text"
                    :value="unitPriceToUnit(measure)"
                    disabled
                />
            </div>
            <div class="me-1">
                <label class="form-label">&nbsp;</label>
                <p class="mb-0 text-black" style="margin-top: 0.5rem">=</p>
            </div>
            <div class="me-1">
                <label class="form-label text-dark fw-normal fs-5">{{ "box.unit_data.quantity".trans() }}</label>
                <input class="form-control text-dark"
                    type="text"
                    :value="unitPriceQuantity(measure)"
                    disabled
                />
            </div>
            <template v-if="measure.unit_price[1]">
                <div class="me-1">
                    <label class="form-label">&nbsp;</label>
                    <p class="mb-0 text-black" style="margin-top: 0.5rem">-</p>
                </div>
                <div class="me-1">
                    <label class="form-label text-dark fw-normal fs-5">{{ "box.unit_data.quantity".trans() }}</label>
                    <input class="form-control text-dark"
                        type="text"
                        :value="measure.unit_price[1].split(' ')[0]"
                        disabled
                    />
                </div>
            </template>
            <div class="me-1">
                <label class="form-label text-dark fw-normal fs-5">{{ "box.unit_data.currency".trans() }}</label>
                <input class="form-control text-dark width-less"
                    type="text"
                    :value="unitPriceCurrency(measure)"
                    disabled
                />
            </div>
        </div>
        <div class="weight-and-unit-total-row border-top border-light mt-2">
            <div class="me-1 mt-1 w-50">
                <label for="box-marketing-package" class="form-label text-dark fw-normal fs-5">
                    {{ "box.unit_data.total".trans() }}
                </label>
                <input
                    id="box-marketing-package"
                    class="form-control text-dark"
                    type="text"
                    :class="{ 'border-danger': isEmpty }"
                    :value="marketingPackageLabel"
                    @change="updateParent"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from "vue"

    const props = defineProps({
        marketingPackageLabel: {
            type: String,
            required: true
        },
        packageGroups: {
            type: Array,
            required: true
        },
        isEmpty: {
            type: Boolean,
            required: true
        }
    })

    const unitPriceToUnit = (measure) => {
        if (
            !measure.unit_price[0] ||
            measure.unit_price[0].split(' ').length < 2 ||
            measure.unit_price[0].split(' ')[1].split('/').length < 2
        ) {
            return ''
        }

        return measure.unit_price[0].split(' ')[1].split('/')[1]
    }

    const unitPriceQuantity = (measure) => {
        if (
            !measure.unit_price[0] ||
            measure.unit_price[0].split(' ').length < 1
        ) {
            return ''
        }

        return measure.unit_price[0].split(' ')[0]
    }

    const unitPriceCurrency = (measure) => {
        if (
            !measure.unit_price[0] ||
            measure.unit_price[0].split(' ').length < 2 ||
            measure.unit_price[0].split(' ')[1].split('/').length < 1
        ) {
            return ''
        }

        return measure.unit_price[0].split(' ')[1].split('/')[0]
    }

    const emit = defineEmits(['valueUpdate'])

    function updateParent(evt) {
        emit('valueUpdate', evt.target.value)
    }
</script>

<style lang="sass" scoped>
    .vr
        border-right: 1px solid #DDE3E9

</style>