<template>
    <div class="filter-dropdown" ref="root">
        <p class="mb-1 fw-normal text-dark fs-5">
            {{ ("catalog.box.filters." + name).trans() }}
        </p>

        <div class="position-relative">
            <select ref="select">
                <option value="">
                    {{ ("catalog.box.filters.placeholder." + name).trans() }}
                </option>
            </select>
            <i class="mdi mdi-triangle fs-7 text-dark position-absolute"></i>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useBacklogCardsStore } from "../../../js/frontend/stores/backlogCards";

const backlogCardsStore = useBacklogCardsStore();

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    selectedItems: {
        type: Object,
        required: true,
    },
    dropdownOptions: {
        type: Object,
        required: true,
    },
    catalogId: {
        type: Number,
        required: true,
    },
});

const emit = defineEmits(["select"]);

const root = ref(null);
const select = ref(null);
const selectedItemsComputed = computed(() => props.selectedItems);

onMounted(() => {
    $(select.value).selectize({
        maxItems: null,
        valueField: "id",
        labelField: "text",
        searchField: ["text"],
        preload: "focus",
        diacritics: true,
        load: async function (query, callback) {
            await backlogCardsStore.fetchSingleFilterData(
                props.name,
                props.catalogId
            );
            return callback(props.dropdownOptions.value);
        },
        onChange: function (selectedValues) {
            emit("select", {
                filterName: props.name,
                filterValues: selectedValues,
            });
        },
    });

    initRemoveOnClick();
});

function initRemoveOnClick() {
    $(root.value).on("click", ".item", function () {
        $(select.value)[0].selectize.removeItem($(this).data("value"));
        $(select.value)[0].selectize.refreshOptions();
    });
}

watch(selectedItemsComputed.value, (newVal, oldVal) => {
    const keys = Object.keys(newVal);
    const allEmpty =
        keys.reduce((acc, curr) => {
            return acc + selectedItemsComputed.value[curr].length;
        }, 0) == 0;

    if (allEmpty) {
        $(select.value)[0].selectize.clear();
    }
});
</script>

<style lang="sass" scoped>
.mdi-triangle
    transform: rotate(180deg)
    top: 9px
    right: 18px
    z-index: 1
    pointer-events: none
</style>
