import { hotReload } from '../helpers/hotReload'
import { updateUsers } from '../api/catalog'
import { showErrorToast } from '../helpers/generalErrorHandler'

class UpdateUsersModal {
    constructor(DomElem = []) {
        if (DomElem.length == 0) {
            return false
        }

        this.DomElem = DomElem
        this.submitButton = this.DomElem.find('.update-catalog-users')
        this.currentSelectedText = this.DomElem.find('.selected-current')
        this.selectedUserIds = []

        this.initCheckboxEvent()
        this.initSubmitEvent()
    }

    initSubmitEvent() {
        this.submitButton.on('click', async () => {
            this.clearSelectedUserIds()
            this.getSelectedUserIds()
            
            try {
                await updateUsers(this.submitButton.data('catalogId'), this.selectedUserIds)

                hotReload(['.sub-header', '#catalogUsersModal'])

            } catch (error) {
                showErrorToast(error)
                throw error
            }
        })
    }

    initCheckboxEvent() {
        this.DomElem.find('.user-form-check-input').on('change', () => {
            this.clearSelectedUserIds()
            this.getSelectedUserIds()
            
            this.currentSelectedText.text(this.selectedUserIds.length)
        })
    }

    getSelectedUserIds() {
        this.DomElem.find('.user-form-check-input:checked').map((index, elem) => {
            return this.selectedUserIds.push($(elem).data('value'))
        })
    }

    clearSelectedUserIds() {
        this.selectedUserIds = []
    }
}

export default UpdateUsersModal