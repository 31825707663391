import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

import { fetchPage, fetchOverviewPage } from '../api/page'

export const useActivePagesStore = defineStore('activePages', () => {
    const data = ref([])
    const overviewData = ref([])
    const loading = ref(true)
    const overviewLoading = ref(true)

    const getData = computed(() => data)
    const getOverviewData = computed(() => overviewData)
    const isLoading = computed(() => loading)
    const isOverviewLoading = computed(() => overviewLoading)

    async function fetchData(pageIdsArray) {
        if (!pageIdsArray) return
        
        loading.value = true

        try {
            if (pageIdsArray.length > 1) {
                let response = await Promise.all([fetchPage(pageIdsArray[0]), fetchPage(pageIdsArray[1])])
                data.value = [response[0], response[1]]

            } else {
                let response = await fetchPage(pageIdsArray[0])
                data.value = [response]
            }

            loading.value = false

        } catch (error) {
            throw error
        }
    }

    async function fetchOverviewData(pageIdsArray) {
        if (!pageIdsArray) return
        
        overviewLoading.value = true

        try {
            if (pageIdsArray.length > 1) {
                let response = await Promise.all([fetchOverviewPage(pageIdsArray[0]), fetchOverviewPage(pageIdsArray[1])])
                overviewData.value = [response[0], response[1]]

            } else {
                let response = await fetchOverviewPage(pageIdsArray[0])
                overviewData.value = [response]
            }

            overviewLoading.value = false

        } catch (error) {
            throw error
        }
    }

    return {
        getData,
        getOverviewData,
        isLoading,
        isOverviewLoading,
        fetchData,
        fetchOverviewData
    }
})