<template>
    <div id="data-tab-overview" class="tab-pane active">

        <LoyaltyFlag v-if="currentData.Type == 'loyalty'" />

        <UneditableGeneralBoxData :data="currentData" :draftData="draftData" />

        <div class="text-data-container mt-3">
            <div class="mb-2" v-if="cardBrand">
                <label class="form-label text-muted fw-bold fs-6 mb-0">
                    {{ "box.text_data.brand".trans() }}
                </label>
                <p class="text-black fs-5">{{ cardBrand.label }}</p>
            </div>

            <div class="mb-2" v-if="cardName">
                <label class="form-label text-muted fw-bold fs-6 mb-0">
                    {{ "box.text_data.name".trans() }}
                </label>
                <p class="text-black fs-5">{{ cardName }}</p>
            </div>

            <div class="mb-2" v-if="cardDescription">
                <label class="form-label text-muted fw-bold fs-6 mb-0">
                    {{ "box.text_data.description".trans() }}
                </label>
                <p class="text-black fs-5">{{ cardDescription }}</p>
            </div>

            <div class="mb-2" v-if="cardNote">
                <label class="form-label text-muted fw-bold fs-6 mb-0">
                    {{ "box.text_data.note".trans() }}
                </label>
                <p class="text-black fs-5">{{ cardNote }}</p>
            </div>

            <div class="mb-2" v-if="weightAndMeasureTotal">
                <label class="form-label text-muted fw-bold fs-6 mb-0">
                    {{ "box.unit_data.total".trans() }}
                </label>
                <p class="text-black fs-5">{{ weightAndMeasureTotal }}</p>
            </div>

            <div class="mb-2" v-if="currentData.Type == 'loyalty' && weightAndMeasureLoyaltyTotal">
                <label class="form-label text-muted fw-bold fs-6 mb-0">
                    {{ "loyalty.unit_data.total".trans() }}
                </label>
                <p class="text-black fs-5">{{ weightAndMeasureLoyaltyTotal }}</p>
            </div>
        </div>

        <div class="box-prices-container d-flex bg-soft-secondary rounded-2 p-3 mt-3">
            <div :class="[!priceFromSwitchSelected ? 'd-none' : '', 'me-1 w-50']">
                <label
                    class="form-label text-dark text-uppercase fw-bold fs-6 lh-1"
                >
                    {{ "box.price_data.currency".trans() }} <br />
                    {{ "box.price_data.price_from".trans() }}
                </label>
                <input
                    v-model="priceFrom"
                    class="form-control text-dark"
                    type="text"
                    disabled
                />
            </div>
            <div :class="[priceFromSwitchSelected ? 'd-none' : '', 'me-1 w-33']">
                <label
                    class="form-label text-dark text-uppercase fw-bold fs-6 lh-1"
                >
                    {{ "box.price_data.currency".trans() }} <br />
                    {{ "box.price_data.discount".trans() }}
                </label>
                <input
                    v-model="discountPrice"
                    class="form-control text-dark"
                    type="text"
                    disabled
                />
            </div>
            <div :class="[priceFromSwitchSelected ? 'd-none' : '', 'me-1 w-33']">
                <label
                    class="form-label text-dark text-uppercase fw-bold fs-6 lh-1"
                >
                    {{ "box.price_data.currency".trans() }} <br />
                    {{ "box.price_data.regular_price".trans() }}
                </label>
                <input
                    v-model="price"
                    class="form-control text-dark"
                    type="text"
                    disabled
                />
            </div>
            <div :class="[priceFromSwitchSelected ? 'w-50' : 'w-33']">
                <label
                    class="form-label text-dark text-uppercase fw-bold fs-6 lh-1"
                >
                    <br />
                    {{ "box.price_data.percentage".trans() }}
                </label>
                <input
                    v-model="discountPercentage"
                    class="form-control text-dark"
                    type="text"
                    placeholder="%"
                    disabled
                />
            </div>
        </div>
        <div class="box-switches-container d-flex align-items-center mt-3 text-dark fs-5">
            <div class="switch-container d-flex justify-content-center w-30">
                <span class="me-1 nowrap">{{
                    "box.switch_data.price".trans()
                }}</span>
                <label class="switch">
                    <p v-if="priceFromSwitchSelected" class="mb-0">{{ 'switch.true'.trans() }}</p>
                    <p v-else class="mb-0">{{ 'switch.false'.trans() }}</p>
                </label>
            </div>
            <span class="vr mx-2 text-light"></span>
            <div class="switch-container d-flex justify-content-center w-30">
                <span class="me-1 nowrap">{{
                    "box.switch_data.web_only".trans()
                }}</span>
                <label class="switch">
                    <p v-if="isWebOnly" class="mb-0">{{ 'switch.true'.trans() }}</p>
                    <p v-else class="mb-0">{{ 'switch.false'.trans() }}</p>
                </label>
            </div>
            <span class="vr mx-2 text-light"></span>
            <div class="switch-container d-flex justify-content-center w-30"
                :class="{ 'w-50': installment }"
            >
                <span class="me-1 nowrap">{{
                    "box.switch_data.installments".trans()
                }}</span>
                <label class="switch">
                    <template v-if="installment">
                        <span class="mb-0">{{ 'switch.true'.trans() }}</span>
                        <span class="ms-2 me-1 nowrap">{{ "box.switch_data.installments.amount".trans() }} {{ installmentAmount }}</span>
                    </template>
                    <p v-else class="mb-0">{{ 'switch.false'.trans() }}</p>
                </label>
            </div>
            <span class="vr mx-2 text-light"></span>
            <div
                class="switch-container justify-content-center w-50 d-flex"
            >
                <span class="me-1 nowrap">{{
                    "box.switch_data.discount".trans()
                }}</span>
                <label class="switch">
                    <p v-if="hasMarketingDiscount" class="mb-0">{{ 'switch.true'.trans() }}, {{ marketingDiscount + ' %' }}</p>
                    <p v-else class="mb-0">{{ 'switch.false'.trans() }}</p>
                </label>
            </div>
        </div>

        <template v-if="draftData && draftData.Type == 'loyalty'">
            <p class="text-dark fw-bold mt-3 mb-0">
                {{ 'Loyalty:'.trans() }}
            </p>
            <div class="box-prices-container d-flex bg-soft-secondary rounded-2 p-3 mt-1">
                <div class="me-1 w-33">
                    <label class="form-label text-dark text-uppercase fw-bold fs-6 lh-1">
                        {{ "box.price_data.currency".trans() }} <br />
                        {{ "loyalty.price".trans() }}
                    </label>
                    <input
                        v-model="loyaltyPrice"
                        class="form-control text-dark"
                        type="text"
                        disabled
                    />
                </div>
                <div class="me-1 w-33">
                    <label class="form-label text-dark text-uppercase fw-bold fs-6 lh-1">
                        {{ "box.price_data.currency".trans() }} <br />
                        {{ "box.price_data.regular_price".trans() }}
                    </label>
                    <input
                        v-model="price"
                        class="form-control text-dark"
                        type="text"
                        disabled
                    />
                </div>
                <div class="w-33">
                    <label class="form-label text-dark text-uppercase fw-bold fs-6 lh-1">
                        <br />
                        {{ "box.price_data.percentage".trans() }}
                    </label>
                    <input
                        v-model="loyaltyPercentage"
                        class="form-control text-dark"
                        type="text"
                        placeholder="%"
                        disabled
                    />
                </div>
            </div>
            <div class="box-switches-container d-flex align-items-center mt-3 text-dark fs-5">
                <div class="switch-container d-flex justify-content-center w-30"></div>
                <div class="switch-container d-flex justify-content-center w-30"></div>
                <div class="switch-container d-flex justify-content-center w-30"></div>
                <div class="switch-container justify-content-center w-50 d-flex">
                    <span class="me-1 nowrap">{{ "loyalty.switch_data.discount".trans() }}</span>
                    <label class="switch">
                        <p v-if="hasLoyaltyMarketingDiscount" class="mb-0">{{ 'switch.true'.trans() }}, {{ loyaltyMarketingDiscount + ' %' }}</p>
                        <p v-else class="mb-0">{{ 'switch.false'.trans() }}</p>
                    </label>
                </div>
            </div>
        </template>

        <div class="d-flex align-items-center mt-4 text-dark fs-5">
            <p class="m-0">
                {{ selectedProductsIds.length }}
                {{ "box.products.from".trans() }}
                {{ allProductIds.length }}
                {{ "box.products.selected".trans() }}
            </p>
        </div>
        <div class="products-container mt-4 ps-2">
            <BoxProductOverview
                v-for="(productId, index) in allProductIds"
                :key="productId"
                :currentData="cardSingleStore.getCurrentProduct(productId)"
                :draftData="cardSingleStore.getDraftProduct(productId)"
                :originalData="cardSingleStore.getOriginalProduct(productId)"
                :cardLevelSelectedImages="cardLevelSelectedImages"
                :index="index + 1"
                :isAdded="addedProductIds.indexOf(productId) >= 0"
                :isRemoved="removedProductIds.indexOf(productId) >= 0"
                :isChanged="changedProductIds.indexOf(productId) >= 0"
                :showFlags="showProductFlags"
            />
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import { useCardSingleStore } from '../../../js/frontend/stores/cardSingle'

    import UneditableGeneralBoxData from '../../components/BoxEdit/UneditableGeneralBoxData'
    import BoxProductOverview from './BoxProductOverview'
    import LoyaltyFlag from '../Flags/LoyaltyFlag'

    const props = defineProps({
        draftData: {
            type: Object,
            required: true,
        },
        currentData: {
            type: Object,
            required: true,
        }
    })

    const cardSingleStore = useCardSingleStore()

    const cardBrand = ref(props.currentData.Brand)
    const cardName = ref(props.currentData.Label)
    const cardDescription = ref(props.currentData.Description)
    const cardNote = ref(props.currentData.DesignerNote)

    const weightAndMeasureTotal = ref(props.currentData.displayMeasureValue)
    const weightAndMeasureLoyaltyTotal = ref(props.currentData.LoyaltyMeasureValue)

    const discountPrice = ref(props.currentData.LowestPrice)
    const price = ref(props.currentData.Price)
    const discountPercentage = ref(props.currentData.Percentage + ' %')

    const loyaltyPrice = ref(props.draftData.LoyaltyPrice)
    const loyaltyPercentage = ref(props.draftData.LoyaltyPercentage ? props.draftData.LoyaltyPercentage + ' %' : '0 %')
    const loyaltyMarketingDiscount = ref((props.draftData.LoyaltyMarketingDiscount ? props.draftData.LoyaltyMarketingDiscount : '0') + ' %')
    const hasLoyaltyMarketingDiscount = ref(props.draftData.LoyaltyMarketingDiscount ? true : false)

    const priceFrom = ref(props.currentData.PriceFrom)
    const isWebOnly = ref(props.currentData.isWebOnly)
    const installment = ref(props.currentData.Installment)
    const installmentAmount = ref(props.currentData.installmentRate)
    const marketingDiscount = ref(props.currentData.MarketingDiscount)
    const hasMarketingDiscount = ref(props.currentData.MarketingDiscount ? true : false)
    const priceFromSwitchSelected = ref((!priceFrom.value || priceFrom.value == '0') ? false : true)

    const allProductIds = ref(cardSingleStore.getAllProductIds)
    const selectedProductsIds = ref(cardSingleStore.getSelectedProducts)

    const addedProductIds = ref(cardSingleStore.getAddedProducts)
    const removedProductIds = ref(cardSingleStore.getRemovedProducts)
    const changedProductIds = ref(cardSingleStore.getChangedProducts)
    const cardLevelSelectedImages = ref(cardSingleStore.getCardLevelSelectedImages)

    const showProductFlags = ref(true)
</script>

<style lang="sass" scoped>
    .w-33
        width: 33.33%
</style>