import axios from 'axios'
import { showErrorToast } from '../helpers/generalErrorHandler'

import { mapCardCommentsData, mapPageCommentsData } from '../mappers/comment'

async function fetchCardCommentsData(id) {
    if (!id) return

    try {
        const response = await axios.get(`/api/comments?model_type=catalog_card&model_id=${id}`)

        if (!response.data.data) return []

        const data = mapCardCommentsData(response.data.data)

        return data || []

    } catch (error) {
        showErrorToast(error)
        return []
    }
}

async function fetchActivePageCommentsData(id) {
    if (!id) return

    try {
        const response = await axios.get(`/api/comments?model_type=catalog_page&model_id=${id}`)

        if (!response.data.data) return []

        const data = mapPageCommentsData(response.data.data)

        return data || []

    } catch (error) {
        showErrorToast(error)
        return []
    }
}

async function newComment(comment, objectId, objectType) {
    if (!comment || !objectId || !objectType) return

    try {
        await axios.post(`/api/add-comment?model_type=${objectType}&model_id=${objectId}`, {
            'content': comment
        })

    } catch (error) {
        showErrorToast(error)
    }
}

async function updateComment(comment, commentId) {
    if (!comment || !commentId) return

    try {
        await axios.post('/api/edit-comment', {
            'content': comment,
            'commentId': commentId
        })

    } catch (error) {
        showErrorToast(error)
    }
}

async function deleteComment(commentId) {
    if (!commentId) return

    try {
        await axios.post('/api/delete-comment', {
            'commentId': commentId
        })

    } catch (error) {
        showErrorToast(error)
    }
}

export {
    fetchCardCommentsData,
    fetchActivePageCommentsData,
    newComment,
    updateComment,
    deleteComment
}