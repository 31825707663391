<template>
    <div class="drawer-box-edit-container">
        <div
            id="drawerBoxEdit"
            class="offcanvas offcanvas-end"
            tabindex="-1"
            aria-labelledby="drawerBoxEditLabel"
            ref="offcanvas"
        >
            <div v-if="isLoading" class="spinner-wrapper">
                <span
                class="spinner-border spinner-border me-1"
                role="status"
                ></span>
            </div>

            <template v-else>
                <div
                class="offcanvas-header drawer-box-edit-header border-bottom ms-0 me-0 ps-0 pe-4 pb-2 ribbon-box bg-white"
                >

                    <status-ribbon v-if="false" :status="draftData.Status"></status-ribbon>

                    <h2
                        id="drawerBoxEditLabel"
                        class="offcanvas-title fw-bold text-black mt-1 px-4"
                    >
                        {{ "drawer.box.title".trans() }}
                    </h2>

                    <button
                        class="btn-close text-reset"
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="offcanvas-body drawer-box-edit-body p-4">
                    <ul class="nav nav-tabs nav-bordered nav-justified w-50">
                        <li class="nav-item data-item">
                            <a
                                class="nav-link fw-bold text-uppercase text-dark active"
                                href="#data-tab"
                                data-bs-toggle="tab"
                                aria-expanded="false"
                            >
                                {{ "box.tab.data".trans() }}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link fw-bold text-uppercase text-dark extra-margin"
                                href="#comments-tab"
                                data-bs-toggle="tab"
                                aria-expanded="true"
                            >
                                {{ "box.tab.comments".trans() }}
                                <i class="mdi mdi-comment-text-multiple-outline ms-1 fs-4 text-muted"
                                    :class="{ 'text-red': draftData.unreadCommentCount > 0 }"
                                ></i>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <BoxEditDataTab
                            :draftData="draftData"
                            :currentData="currentData"
                            :originalCardData="originalCardData"
                            :pricesForDropdown="pricesForDropdown"
                            @reloadBacklogAndPages="reloadAll"
                            ref="dataTabComponent"
                        />
                        <BoxEditCommentsTab :id="currentData.id" :isFinished="catalogIsFinished"/>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, onBeforeUnmount } from "vue";

    import { useCardSingleStore } from "../../../js/frontend/stores/cardSingle";
    import { useBacklogCardsStore } from "../../../js/frontend/stores/backlogCards";
    import { unlock } from '../../../js/frontend/api/card'

    import BoxEditDataTab from "./BoxEditDataTab";
    import BoxEditCommentsTab from "./BoxEditCommentsTab";
    import StatusRibbon from "./StatusRibbon";

    const offcanvas = ref(null);
    const dataTabComponent = ref(null)

    const props = defineProps({
        catalogId: {
            type: Number,
            required: true
        },
        catalogIsFinished: {
            type: Boolean,
            required: true
        }
    })

    const emit = defineEmits(['reloadPages', 'reloadSinglePage'])

    const cardSingleStore = useCardSingleStore();
    const backlogCardsStore = useBacklogCardsStore();

    onMounted(() => {
        $(offcanvas.value).on("show.bs.offcanvas", () => {
            backlogCardsStore.fetchAllCards(props.catalogId);
        });

        $(offcanvas.value).on("hidden.bs.offcanvas", () => {
            unlock(currentData.value.id)
            cardSingleStore.setNewSplitCard(null);

            emit('reloadPages')
        });
    });

    onBeforeUnmount(() => {
        $(offcanvas.value).off("hidden.bs.offcanvas", "**");
    });

    const draftData = ref(cardSingleStore.getDraftData);
    const currentData = ref(cardSingleStore.getCurrentData);
    const pricesForDropdown = ref(cardSingleStore.getValuesForPriceDropdown);
    const isLoading = ref(cardSingleStore.isLoading);
    const originalCardData = ref(cardSingleStore.getOriginalCardData)

    function triggerSaveAndPublishData(isDeleteOnly = false) {
        dataTabComponent.value.saveAndPublishData(isDeleteOnly)
    }

    function reloadAll() {
        backlogCardsStore.fetchData(props.catalogId);
        emit('reloadPages')
    }

    defineExpose({
        triggerSaveAndPublishData
    })

</script>

<style lang="sass" scoped>
    .spinner-wrapper
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center

        .spinner-border
            width: 4.5rem
            height: 4.5rem

    .extra-margin
        margin-bottom: -5px

        &.active
            margin-bottom: -1px

    .data-item
        .nav-link
            padding-top: 15px
            padding-bottom: 10px

    .comment-number
        left: 50%
        transform: translateX(-50%)
        top: 4px
        font-style: normal
</style>
