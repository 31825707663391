import { dashboardMakeRequest } from '../helpers/dashboardMakeRequest'

class DashboardSearch {
    constructor(DomElem = []) {
        if (DomElem.length == 0) {
            return false
        }

        this.DomElem = DomElem
        this.search = this.DomElem.find('.dashboard-search')
        this.searchButton = this.DomElem.find('.dashboard-search-button')
        this.clearButton = this.DomElem.find('.search-term-container')

        this.searchQueryParam = this.DomElem.data('searchQueryParam') || 'searchTerm'

        this.initSearch()
    }

    initSearch() {
        this.initKeyboardEvent()
        this.initSearchButton()
        this.initClearButton()
    }

    initKeyboardEvent() {
        this.search.on('keydown', e => {
            if (e.keyCode == 13) {
                if (!this.search.val()) return false

                dashboardMakeRequest(this.searchQueryParam, this.search.val(), false, true)
            }
        })
    }

    initSearchButton() {
        if (this.searchButton.length == 0) return false

        this.searchButton.on('click', () => {
            if (!this.search.val()) return false
            
            dashboardMakeRequest(this.searchQueryParam, this.search.val(), false, true)
        })
    }

    initClearButton() {
        this.clearButton.on('click', () => {
            dashboardMakeRequest(this.searchQueryParam, this.search.val(), true, true)
        })
    }
}

export default DashboardSearch