import { dashboardMakeRequest } from "../helpers/dashboardMakeRequest";
import "../helpers/updateUrlParameter";
import "../helpers/getUrlParameters";

class DashboardValidityFilter {
    constructor(DomElem = []) {
        if (DomElem.length == 0) {
            return false;
        }

        this.DomElem = DomElem;
        this.datepickers = $(this.DomElem).find(".datepicker");
        this.clearButton = this.DomElem.find(".clear-button");
        this.submitButton = this.DomElem.find(".submit-button");
        this.errorMessage = this.DomElem.find(".alert");

        this.dateStart = [];
        this.dateEnd = [];

        this.dateStartFormatted = getUrlParameters(window.location.href)['startDate'] || "";
        this.dateEndFormatted = getUrlParameters(window.location.href)['endDate'] || "";

        this.initFilter();
        this.initEvents();
    }

    initFilter() {
        const self = this;

        this.datepickers.flatpickr({
            dateFormat: "d.m.Y",
            static: true,
            onChange: function (selectedDates, dateStr, instance) {
                if ($(instance.element).hasClass("datepicker-validity-start")) {
                    self.dateStart = selectedDates;
                    self.dateStartFormatted = dateStr;

                    if (!self.hasEnd() && selectedDates.length > 0) {
                        $(".datepicker-validity-end")[0]._flatpickr.setDate(
                            selectedDates,
                            true
                        );
                    }
                } else {
                    self.dateEnd = selectedDates;
                    self.dateEndFormatted = dateStr;

                    if (!self.hasStart() && selectedDates.length > 0) {
                        $(".datepicker-validity-start")[0]._flatpickr.setDate(
                            selectedDates,
                            true
                        );
                    }
                }

                if (self.hasStart() && self.hasEnd()) {
                    self.submitButton.removeClass("disabled");

                    if (!self.dateRangeIsValid()) {
                        self.errorMessage.removeClass("d-none");
                        self.submitButton.addClass("disabled");
                    } else {
                        self.errorMessage.addClass("d-none");
                        self.submitButton.removeClass("disabled");
                    }
                } else {
                    self.errorMessage.addClass("d-none");
                }
            },
        });
    }

    initEvents() {
        this.clearButton.on("click", () => {
            this.DomElem.find($(".datepicker")).each(function () {
                this._flatpickr.clear();
            });

            this.submitButton.addClass("disabled");

            if (
                window.location.href.includes("startDate") &&
                window.location.href.includes("endDate")
            ) {
                this.submitButton.trigger("click");
            }
        });

        this.submitButton.on("click", () => {
            let url = window.location.href;
            url = updateUrlParameter(url, "startDate", this.dateStartFormatted);

            if (typeof window.history.pushState != "undefined") {
                window.history.pushState(null, null, url);
            }

            dashboardMakeRequest("endDate", this.dateEndFormatted, false, true);
        });

        $(".flatpickr-calendar").on("click", (e) => {
            e.stopPropagation();
        });
    }

    hasStart() {
        return this.dateStart.length > 0;
    }

    hasEnd() {
        return this.dateEnd.length > 0;
    }

    dateRangeIsValid() {
        return moment(this.dateStart[0]) <= moment(this.dateEnd[0]);
    }
}

export default DashboardValidityFilter;
