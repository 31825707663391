<template>
    <div
        class="catalog-search-container bg-soft-secondary rounded-2 p-2 p-rem input-group"
    >
        <input
            v-model="searchTerm"
            @keyup.enter="search"
            class="form-control catalog-search"
            type="text"
            :placeholder="'catalog.search.placeholder'.trans()"
        />

        <button
            class="catalog-filters-button input-group-text btn mx-2 py-2 btn-outline fw-bold fs-5 text-uppercase rounded dropdown-toggle cursor-pointer position-relative"
            :class="{ 'text-dark border-dark disabled': isFinished, 'text-red border-red': !isFinished }"
            type="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-haspopup="true"
            aria-expanded="false"
            ref="dropdownToggler"
        >
            {{ "catalog.box.filters".trans() }}

            <span
                v-if="activeFiltersNumber > 0"
                class="position-absolute top-0 start-100 translate-middle rounded-circle badge bg-dark fs-5"
            >
                {{ activeFiltersNumber }}
            </span>
        </button>

        <div class="dropdown-menu p-3 catalog-filters-menu w-100">
            <p class="mb-1 fw-bold fs-4 text-black">
                {{ "catalog.box.filters.title".trans() }}
            </p>

            <Filter
                class="mb-1"
                name="topics"
                :catalogId="catalogId"
                :selectedItems="filterData"
                :dropdownOptions="backlogCardsStore.getTopics"
                @select="updateSelectedFilters"
            />

            <Filter
                class="mb-1"
                name="robni-program"
                :catalogId="catalogId"
                :selectedItems="filterData"
                :dropdownOptions="backlogCardsStore.getRobniProgram"
                @select="updateSelectedFilters"
            />

            <input
                v-model="filterVirtual"
                id="filter-virtual"
                class="form-check-input border-muted cursor-pointer"
                type="checkbox"
                name="filter-virtual"
                @change="updateVirtualFilter"
            />
            <label class="text-dark ps-1 cursor-pointer" for="filter-virtual">
                {{ "catalog.box.filter.virtual".trans() }}
            </label>

            <button
                v-if="activeFiltersNumber > 0"
                class="btn btn-shadow d-block p-0 mt-3 text-decoration-underline text-dark"
                @click="clearAllFilters"
            >
                {{ "catalog.box.filter.clear_all".trans() }}
            </button>

            <div class="mt-3 d-flex align-items-center">
                <button
                    @click="confirmFilters"
                    class="btn btn-dark text-uppercase fw-bold me-2"
                    type="button"
                >
                    {{ "catalog.box.filter.confirm".trans() }}
                </button>
                <button
                    @click="denyFilters"
                    class="btn btn-outline border-red text-uppercase text-red fw-bold"
                    type="button"
                >
                    {{ "catalog.box.filter.decline".trans() }}
                </button>
            </div>
        </div>

        <button
            class="catalog-search-button input-group-text btn py-2 btn-dark fw-bold fs-5 text-uppercase rounded"
            :class="{ 'disabled': isFinished }"
            type="button"
            @click="search"
        >
            {{ "catalog.box.search".trans() }}
        </button>
    </div>
</template>

<script setup>
    import { computed, ref, watch } from "vue";
    import Filter from "./Filter.vue";

    import { useBacklogCardsStore } from "../../../js/frontend/stores/backlogCards";

    const backlogCardsStore = useBacklogCardsStore();

    const props = defineProps({
        catalogId: {
            type: Number,
            required: true,
        },
        isFinished: {
            type: Boolean,
            required: true
        }
    });

    const dropdownToggler = ref(null);

    const filterVirtual = ref(false);
    const filterData = ref({});
    const searchTerm = ref(null);

    const activeFiltersNumber = computed(() => {
        const keys = Object.keys(filterData.value);

        return keys.filter((key) => filterData.value[key].length > 0).length;
    });

    watch(searchTerm, () => {
        setPayload();
    })

    function updateSelectedFilters(emitData) {
        const { filterName, filterValues } = emitData;

        filterData.value[filterName] = filterValues;
    }

    function updateVirtualFilter() {
        filterData.value["virtual"] = filterVirtual.value
            ? [filterVirtual.value]
            : [];
    }

    function clearAllFilters() {
        const keys = Object.keys(filterData.value);

        keys.forEach((key) => (filterData.value[key] = []));

        filterVirtual.value = false;
    }

    function confirmFilters() {
        $(dropdownToggler.value).dropdown("hide");
    }

    function denyFilters() {
        clearAllFilters();
        $(dropdownToggler.value).dropdown("hide");
    }

    async function search() {
        setPayload();
        await backlogCardsStore.fetchData(props.catalogId);
    }

    function setPayload() {
        let payload = {};

        if (filterData.value["topics"] && filterData.value["topics"].length > 0) {
            payload["topic"] = filterData.value["topics"];
        }
        if (
            filterData.value["robni-program"] &&
            filterData.value["robni-program"].length > 0
        ) {
            payload["robniProgram"] = filterData.value["robni-program"];
        }
        if (filterVirtual.value) {
            payload["virtual"] = [true];
        }
        if (searchTerm.value) {
            payload["searchTerm"] = [searchTerm.value];
        } else {
            payload["searchTerm"] = [''];
        }

        backlogCardsStore.setPayload(payload);
    }
</script>

<style lang="sass" scoped>
    .catalog-filters-menu
        background-color: #F6F8FB

    .rounded-circle
        bottom: 26px
        line-height: 16px
</style>
