import axios from 'axios'

const Translations = {
    init: async function () {
        this.setStringPrototype()
    },

    setStringPrototype: function() {
        const _self = this

        // Prototype function on strings used for creating pimcore translations
        String.prototype.trans = function() {
            const key = this.toString()
            const { website_translations } = window

            if (website_translations) {
                if (key in website_translations) {
                    return website_translations[key] != '' ? website_translations[key] : key

                // if it's a new key, create it
                } else {
                    _self.createTranslation(key)
                    return key
                }
            }

            return key
        }
    },
    createTranslation: async function(translationKey) {
        try {
            const { website_translations, admin_language_default } = window

            await axios.post('/translation/create', {
                'domain': 'messages',
                'language': admin_language_default ? admin_language_default : 'en',
                'key': translationKey
            })

            website_translations[translationKey] == ''

        } catch (error) {
            throw error
        }
    }
}

export default Translations
