<template>
    <div id="headerSearchDrawer"
        class="offcanvas offcanvas-end"
        tabindex="-1"
        aria-labelledby="headerSearchDrawerLabel"
        ref="offcanvas"
    >
        <div class="offcanvas-header drawer-box-edit-header border-bottom mx-4 px-0 pb-2">
            <h2 id="headerSearchDrawerLabel" class="offcanvas-title fw-bold text-black mt-1">
                {{ "catalog.subheader.search".trans() }}
            </h2>
            <button class="btn-close text-reset"
                type="button"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
            ></button>
        </div>
        <div class="offcanvas-body drawer-box-edit-body p-4">
            <div class="search-container d-flex align-items-center">
                <input
                    v-model="searchTerm"
                    @keyup="search"
                    class="form-control catalog-search py-2"
                    type="text"
                    :placeholder="'catalog.search.drawer.placeholder'.trans()"
                />
            </div>
            <div v-if="searchResults" class="search-results-container custom-scrollbar mt-3">
                <div v-if="isLoading" class="spinner-wrapper d-flex">
                    <span class="spinner-border spinner-border mx-auto mt-4"
                        role="status"
                    ></span>
                </div>
                <template v-else>
                    <p class="text-black fs-5">
                        {{ 'search.results.total'.trans() }}
                        {{ searchResults.cardMatches.length + searchResults.productMatches.length }}
                        {{ 'search.results.results'.trans() }}
                        ({{ searchResults.cardMatches.length }} {{ 'search.results.boxes'.trans() }}, {{ searchResults.productMatches.length }} {{ 'search.results.products'.trans() }})</p>
                    <div v-for="card in searchResults.cardMatches"
                        class="search-result text-black border-bottom mt-4"
                        :key="card.cardId"
                    >
                        <p class="fs-3 fw-bold mb-1">{{ card.label }}</p>
                        <div class="pb-2 d-flex align-items-center">
                            <span class="fs-5 mb-0">
                                {{ 'search.results.page'.trans() }}
                                <b>{{ card.pagePosition || 'Backlog' }}</b>
                            </span>
                            <template v-if="!catalogIsFinished">
                                <span class="vr mx-2 text-light-secondary"></span>
                                <button v-if="card.pagePosition"
                                    class="btn btn-outline p-0 m-0 text-black text-decoration-underline"
                                    @click="openPage(card.pagePosition, card.pageId, card.cardId)"
                                >
                                    {{ 'search.results.page_open'.trans() }}
                                </button>
                                <button v-else
                                    class="btn btn-outline p-0 m-0 text-black text-decoration-underline"
                                    @click="highlightBacklog(card.cardId)"
                                >
                                    {{ 'search.results.backlog_open'.trans() }}
                                </button>
                                <span class="vr mx-2 text-light-secondary"></span>
                                <button class="btn btn-outline p-0 m-0 text-black text-decoration-underline"
                                    @click="openCard(card.pagePosition, card.pageId, card.cardId)"
                                >
                                    {{ 'search.results.box_open'.trans() }}
                                </button>
                            </template>
                        </div>
                    </div>

                    <div v-for="product in searchResults.productMatches"
                        class="search-result text-black border-bottom mt-4"
                        :key="product.cardId * 10"
                    >
                        <p class="fs-3 fw-bold mb-1">{{ product.productName }}</p>
                        <div class="pb-2 d-flex align-items-center">
                            <span class="fs-5 mb-0">
                                {{ 'search.results.page'.trans() }}
                                <b>{{ product.pagePosition || 'Backlog' }}</b>
                            </span>
                            <span class="vr mx-2 text-light-secondary"></span>
                            <span class="fs-5 mb-0">
                                {{ 'search.results.box'.trans() }}
                                <b>{{ product.cardLabel.length > 22 ? product.cardLabel.slice(0, 22) + '...' : product.cardLabel }}</b>
                            </span>
                            <span class="vr mx-2 text-light-secondary"></span>
                            <span class="fs-5 mb-0">
                                {{ 'search.results.brand'.trans() }}
                                <b v-if="product.brand">
                                    {{ product.brand.length > 12 ? product.brand.slice(0, 12) + '...' : product.brand }}
                                </b>
                                <b v-else>
                                    -
                                </b>
                            </span>
                            <span class="vr mx-2 text-light-secondary"></span>
                            <span class="fs-5 mb-0">
                                {{ 'search.results.code'.trans() }}
                                <b>{{ product.code }}</b>
                            </span>
                            <template v-if="!catalogIsFinished">
                                <span class="vr mx-2 text-light-secondary"></span>
                                <button v-if="product.pagePosition"
                                    class="btn btn-outline p-0 m-0 text-black text-decoration-underline"
                                    @click="openPage(product.pagePosition, product.pageId, product.cardId)"
                                >
                                    {{ 'search.results.page_open'.trans() }}
                                </button>
                                <button v-else
                                    class="btn btn-outline p-0 m-0 text-black text-decoration-underline"
                                    @click="highlightBacklog(product.cardId)"
                                >
                                    {{ 'search.results.backlog_open'.trans() }}
                                </button>
                                <span class="vr mx-2 text-light-secondary"></span>
                                <button class="btn btn-outline p-0 m-0 text-black text-decoration-underline"
                                    @click="openCard(product.pagePosition, product.pageId, product.cardId)"
                                >
                                    {{ 'search.results.box_open'.trans() }}
                                </button>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
            <div v-else-if="isLoading && searchTerm.length > 2" class="spinner-wrapper d-flex">
                <span class="spinner-border spinner-border mx-auto mt-4"
                    role="status"
                ></span>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, onMounted } from "vue"
    import { useSearchResultsStore } from "../../js/frontend/stores/searchResults"
    import { usePageListStore } from "../../js/frontend/stores/pageList"
    import { useCardSingleStore } from "../../js/frontend/stores/cardSingle"
    import { useSplideCarouselStore } from "../../js/frontend/stores/splideCarousel"

    const searchResultsStore = useSearchResultsStore()
    const pageListStore = usePageListStore()
    const cardSingleStore = useCardSingleStore()
    const splideStore = useSplideCarouselStore()

    const props = defineProps({
        catalogIsFinished: {
            type: Boolean,
            required: true
        },
        catalogId: {
            type: Number,
            required: true
        }
    })

    const offcanvas = ref('')
    const searchTerm = ref('')

    const searchResults = ref(searchResultsStore.getResults)
    const isLoading = ref(searchResultsStore.isLoading)

    const pagesCarouselData = ref(pageListStore.getPairs)

    let timer

    function search() {
        if (timer) clearTimeout(timer)

        timer = setTimeout(() => {
            searchResultsStore.fetchSearchResults(props.catalogId, searchTerm.value.length <= 2 ? '' : searchTerm.value)
        }, 500)
    }

    function openPage(pagePosition, pageId, cardId) {
        bootstrap.Offcanvas.getInstance(offcanvas.value).hide()

        const carouselIndex = findCarouselIndex(pagePosition)

        navigateToSlide(carouselIndex)

        setTimeout(() => {
            highlightCard(cardId, 6)

            const highlightedCard = document.getElementsByClassName("splide__slide is-active")[0]

            if (!highlightedCard) return

            highlightedCard.scrollIntoView()
            
        }, 500)
    }

    function navigateToSlide(index) {
        splideStore.goToSlide(index)
    }

    async function openCard(pagePosition, pageId, cardId) {
        if (pagePosition) {
            openPage(pagePosition, pageId)
        }

        bootstrap.Offcanvas.getInstance(offcanvas.value).hide()
        
        const myOffcanvas = document.getElementById("drawerBoxEdit")
        const bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)

        const isLocked =
            (await cardSingleStore.fetchData(cardId)) == "locked" ? true : false

        if (isLocked) {
            const lockedCardModal = new bootstrap.Modal(
                document.getElementById("lockedCardModal")
            )
            lockedCardModal.show()
        } else {
            bsOffcanvas.show()
        }
    }

    function highlightBacklog(cardId) {
        bootstrap.Offcanvas.getInstance(offcanvas.value).hide()

        $('.card-highlight').removeClass('card-highlight')
        $(`[data-highlight-card-id="${cardId}"]`).addClass('card-highlight')

        setTimeout(() => {
            highlightCard(cardId, 6)
        }, 500)
    }

    function highlightCard(cardId, maxRrepeats) {
        if (maxRrepeats == 0) return

        try {
            $('.card-highlight').removeClass('card-highlight')

            $(`[data-highlight-card-id="${cardId}"]`).addClass('card-highlight')
            $(`[data-highlight-card-id="${cardId}"]`)[0].scrollIntoView(false)

        } catch {
            setTimeout(() => {
                highlightCard(cardId, maxRrepeats - 1)
            }, 500)
        }
    }

    onMounted(() => {
        $(offcanvas.value).on('hide.bs.offcanvas', () => {
            searchTerm.value = ''
            searchResults.value = null
        })
    })

    function findCarouselIndex(pagePosition) {
        const index = pagesCarouselData.value.findIndex(el => {
            if (el.length == 0) {
                return el[0].Position == pagePosition
            } else {
                return el[0].Position == pagePosition || el[1].Position == pagePosition
            }
        })

        return index
    }
</script>

<style lang="sass" scoped>
    .search-results-container
        min-height: 100px
        max-height: calc(100vh - 225px)
        overflow: auto
</style>
