import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

import { fetchProducts } from '../api/card'

export const useCardStore = defineStore('card', () => {
    const products = ref(null)
    const loadingProducts = ref(true)

    const getProducts = computed(() => products)
    const isLoadingProducts = computed(() => loadingProducts)

    async function fetchCardProducts(cardId) {
        try {
            loadingProducts.value = true

            const response = await fetchProducts(cardId)

            products.value = response

            loadingProducts.value = false

        } catch (error) {
            loadingProducts.value = false
            throw error
        }
    }

    return {
        getProducts,
        isLoadingProducts,
        fetchCardProducts
    }
})
