<template>
    <div
        class="modal fade"
        id="lockedCardModal"
        tabindex="-1"
        aria-labelledby="lockedCardModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body text-black">
                    <h3
                        class="modal-title text-black mb-3"
                        id="lockedCardModalLabel"
                    >
                        {{ "confirm_locked_card_open.modal.title".trans() }}
                    </h3>
                    <h5>
                        {{ "confirm_locked_card_open.modal.text".trans() }}
                    </h5>
                </div>
                <div class="modal-footer">
                    <button
                        @click="confirmCardOpen"
                        type="button"
                        class="btn btn-dark text-uppercase fw-bold"
                        data-bs-dismiss="modal"
                    >
                        {{ "confirm_locked_card_open.modal.confirm".trans() }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-light text-uppercase fw-bold"
                        data-bs-dismiss="modal"
                    >
                        {{ "confirm_locked_card_open.modal.close".trans() }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useCardSingleStore } from "../../js/frontend/stores/cardSingle";
const cardSingleStore = useCardSingleStore();

const lockedCardId = cardSingleStore.getLockedCardFor;

const confirmCardOpen = () => {
    cardSingleStore.fetchData(lockedCardId.value, true);

    const myOffcanvas = document.getElementById("drawerBoxEdit");
    const bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
    bsOffcanvas.show();
};
</script>
