import axios from 'axios'

const REG_EXP = /image\-thumb\_\_(\d+)\_\_([^\/]+)/;
var timeout = null;
var counter = 0;
var thumbnails = [];
function logFailedImage(imgSrc) {
    let match = imgSrc.match(REG_EXP);
    if (!match) {
        return;
    }

    if (timeout) {
        clearTimeout(timeout);
    }

    if (counter > 100) {
        executeMissingImageRequest(thumbnails);
        counter = 0;
        thumbnails = [];
    }

    thumbnails.push([match[1], match[2]].join('+'));
    counter++;

    timeout = setTimeout(function () {
        executeMissingImageRequest(thumbnails);
        counter = 0;
        thumbnails = [];
    }, 400);
}

const executeMissingImageRequest = function (thumbnails) {
    axios.post(`/missing-image`, {
        thumbnails: thumbnails
    });
}

function logAndSwapFailedImgSrc() {
    logFailedImage($(this).attr('src'))

    $(this).attr('src', '/bundles/pimcoreadmin/img/filetype-not-supported.svg')
}

export {
    logAndSwapFailedImgSrc
}