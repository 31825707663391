<template>
    <div
        class="modal fade"
        id="mergeCardModal"
        tabindex="-1"
        aria-labelledby="mergeCardModalLabel"
        aria-hidden="true"
        data-bs-keyboard="false"
        ref="modal"
    >
        <div class="modal-dialog">
            <div class="modal-content p-3">
                <div class="modal-body text-black">
                    <h3
                        class="modal-title text-black mb-3 fw-bold"
                        id="mergeCardModalLabel"
                    >
                        {{ "merge_card.modal.title".trans() }}
                    </h3>

                    <h5>
                        {{ "merge_card.modal.text".trans() }}
                    </h5>

                    <p class="mb-1 mt-2 fw-normal text-dark fs-5">
                        {{ "merge_card.modal.card_name".trans() }}
                    </p>
                    <div class="position-relative">
                        <select ref="select">
                            <option value="">
                                {{ "merge_card.options.placeholder".trans() }}
                            </option>
                        </select>
                        <i
                            class="mdi mdi-triangle fs-7 text-dark position-absolute"
                        ></i>
                    </div>

                    <p
                        v-if="!originCard || !destinationCard"
                        class="text-dark mt-3 fw-bold"
                    >
                        {{
                            "merge_card.please_select_a_card_to_choose_attributes_for_merge".trans()
                        }}
                    </p>

                    <p></p>

                    <div v-if="originCard && destinationCard">
                        <p class="text-black">
                            {{ "merge_card.choose_attributes".trans() }}
                        </p>
                        <div
                            class="card-attributes mt-1"
                            @change="handleRadioChange"
                        >
                            <div class="d-flex justify-content-around">
                                <p class="text-black fw-bold w-50">
                                    {{ originCard.originName }}
                                </p>
                                <p class="text-black fw-bold w-50">
                                    {{ destinationCard.current.originName }}
                                </p>
                            </div>
                            <!-- BRAND -->
                            <div
                                v-if="
                                    originCard.Brand ||
                                    destinationCard.current.Brand
                                "
                                class="radio-btn-pair d-flex justify-content-around"
                            >
                                <label
                                    :for="'merge-card-brand' + originCard.id"
                                    class="form-check-label cursor-pointer d-flex align-items-center w-50"
                                >
                                    <input
                                        :id="'merge-card-brand' + originCard.id"
                                        class="form-check-input border-muted cursor-pointer flex-shrink-0"
                                        type="radio"
                                        name="merge-card-brand"
                                        value="Brand:0"
                                        :checked="configObj.Brand == 0"
                                    />
                                    <div class="mx-2">
                                        <p class="text-dark fw-bold mb-0 fs-6">
                                            {{ "merge_card.brand".trans() }}
                                        </p>
                                        <p class="text-dark mb-0 fs-5">
                                            {{ originCard.Brand.label }}
                                        </p>
                                    </div>
                                </label>
                                <label
                                    :for="
                                        'merge-card-brand' +
                                        destinationCard.current.id
                                    "
                                    class="form-check-label cursor-pointer d-flex align-items-center w-50"
                                >
                                    <input
                                        :id="
                                            'merge-card-brand' +
                                            destinationCard.current.id
                                        "
                                        class="form-check-input border-muted cursor-pointer flex-shrink-0"
                                        type="radio"
                                        name="merge-card-brand"
                                        value="Brand:1"
                                        :checked="configObj.Brand == 1"
                                    />
                                    <div class="mx-2">
                                        <p class="text-dark fw-bold mb-0 fs-6">
                                            {{ "merge_card.brand".trans() }}
                                        </p>
                                        <p class="text-dark mb-0 fs-5">
                                            {{
                                                destinationCard.current.Brand
                                                    .label
                                            }}
                                        </p>
                                    </div>
                                </label>
                            </div>
                            <!-- NAME -->
                            <div
                                class="radio-btn-pair d-flex justify-content-around mt-1"
                            >
                                <label
                                    :for="'merge-card-name' + originCard.id"
                                    class="form-check-label cursor-pointer d-flex align-items-center w-50"
                                >
                                    <input
                                        :id="'merge-card-name' + originCard.id"
                                        class="form-check-input border-muted cursor-pointer flex-shrink-0"
                                        type="radio"
                                        name="merge-card-name"
                                        value="Label:0"
                                        :checked="configObj.Label == 0"
                                    />
                                    <div class="mx-2">
                                        <p class="text-dark fw-bold mb-0 fs-6">
                                            {{ "merge_card.name".trans() }}
                                        </p>
                                        <p class="text-dark mb-0 fs-5">
                                            {{ originCard.Label }}
                                        </p>
                                    </div>
                                </label>
                                <label
                                    :for="
                                        'merge-card-name' +
                                        destinationCard.current.id
                                    "
                                    class="form-check-label cursor-pointer d-flex align-items-center w-50"
                                >
                                    <input
                                        :id="
                                            'merge-card-name' +
                                            destinationCard.current.id
                                        "
                                        class="form-check-input border-muted cursor-pointer flex-shrink-0"
                                        type="radio"
                                        name="merge-card-name"
                                        value="Label:1"
                                        :checked="configObj.Label == 1"
                                    />
                                    <div class="mx-2">
                                        <p class="text-dark fw-bold mb-0 fs-6">
                                            {{ "merge_card.name".trans() }}
                                        </p>
                                        <p class="text-dark mb-0 fs-5">
                                            {{ destinationCard.current.Label }}
                                        </p>
                                    </div>
                                </label>
                            </div>
                            <!-- DESCRIPTION -->
                            <div
                                class="radio-btn-pair d-flex justify-content-around mt-1"
                            >
                                <label
                                    :for="'merge-card-desc' + originCard.id"
                                    class="form-check-label cursor-pointer d-flex align-items-center w-50"
                                >
                                    <input
                                        :id="'merge-card-desc' + originCard.id"
                                        class="form-check-input border-muted cursor-pointer flex-shrink-0"
                                        type="radio"
                                        name="merge-card-desc"
                                        value="Description:0"
                                        :checked="configObj.Description == 0"
                                    />
                                    <div class="mx-2">
                                        <p class="text-dark fw-bold mb-0 fs-6">
                                            {{ "merge_card.desc".trans() }}
                                        </p>
                                        <p class="text-dark mb-0 fs-5">
                                            {{ originCard.Description }}
                                        </p>
                                    </div>
                                </label>
                                <label
                                    :for="
                                        'merge-card-desc' +
                                        destinationCard.current.id
                                    "
                                    class="form-check-label cursor-pointer d-flex align-items-center w-50"
                                >
                                    <input
                                        :id="
                                            'merge-card-desc' +
                                            destinationCard.current.id
                                        "
                                        class="form-check-input border-muted cursor-pointer flex-shrink-0"
                                        type="radio"
                                        name="merge-card-desc"
                                        value="Description:1"
                                        :checked="configObj.Description == 1"
                                    />
                                    <div class="mx-2">
                                        <p class="text-dark fw-bold mb-0 fs-6">
                                            {{ "merge_card.desc".trans() }}
                                        </p>
                                        <p class="text-dark mb-0 fs-5">
                                            {{
                                                destinationCard.current
                                                    .Description
                                            }}
                                        </p>
                                    </div>
                                </label>
                            </div>
                            <!-- PRICE FROM -->
                            <div
                                class="radio-btn-pair d-flex justify-content-around mt-1"
                            >
                                <label
                                    :for="
                                        'merge-card-price-from' + originCard.id
                                    "
                                    class="form-check-label cursor-pointer d-flex align-items-center w-50"
                                >
                                    <input
                                        :id="
                                            'merge-card-price-from' +
                                            originCard.id
                                        "
                                        class="form-check-input border-muted cursor-pointer"
                                        type="radio"
                                        name="merge-card-price-from"
                                        value="PriceFrom:0"
                                        :checked="configObj.PriceFrom == 0"
                                    />
                                    <div class="mx-2">
                                        <p class="text-dark fw-bold mb-0 fs-6">
                                            {{
                                                "merge_card.price-from".trans()
                                            }}
                                        </p>
                                        <p class="text-dark mb-0 fs-5">
                                            {{
                                                originCard.PriceFrom
                                                    ? "merge_card.yes".trans()
                                                    : "merge_card.no".trans()
                                            }}
                                        </p>
                                    </div>
                                </label>
                                <label
                                    :for="
                                        'merge-card-price-from' +
                                        destinationCard.current.id
                                    "
                                    class="form-check-label cursor-pointer d-flex align-items-center w-50"
                                >
                                    <input
                                        :id="
                                            'merge-card-price-from' +
                                            destinationCard.current.id
                                        "
                                        class="form-check-input border-muted cursor-pointer"
                                        type="radio"
                                        name="merge-card-price-from"
                                        value="PriceFrom:1"
                                        :checked="configObj.PriceFrom == 1"
                                    />
                                    <div class="mx-2">
                                        <p class="text-dark fw-bold mb-0 fs-6">
                                            {{
                                                "merge_card.price-from".trans()
                                            }}
                                        </p>
                                        <p class="text-dark mb-0 fs-5">
                                            {{
                                                destinationCard.current
                                                    .PriceFrom
                                                    ? "merge_card.yes".trans()
                                                    : "merge_card.no".trans()
                                            }}
                                        </p>
                                    </div>
                                </label>
                            </div>
                            <!-- INSTALLMENT -->
                            <div
                                class="radio-btn-pair d-flex justify-content-around mt-1"
                            >
                                <label
                                    :for="
                                        'merge-card-installment' + originCard.id
                                    "
                                    class="form-check-label cursor-pointer d-flex align-items-center w-50"
                                >
                                    <input
                                        :id="
                                            'merge-card-installment' +
                                            originCard.id
                                        "
                                        class="form-check-input border-muted cursor-pointer"
                                        type="radio"
                                        name="merge-card-installment"
                                        value="Installment:0"
                                        :checked="configObj.Installment == 0"
                                    />
                                    <div class="mx-2">
                                        <p class="text-dark fw-bold mb-0 fs-6">
                                            {{
                                                "merge_card.installment".trans()
                                            }}
                                        </p>
                                        <p class="text-dark mb-0 fs-5">
                                            {{
                                                originCard.Installment == 1
                                                    ? "merge_card.no".trans()
                                                    : "merge_card.yes".trans()
                                            }}
                                        </p>
                                    </div>
                                </label>
                                <label
                                    :for="
                                        'merge-card-installment' +
                                        destinationCard.current.id
                                    "
                                    class="form-check-label cursor-pointer d-flex align-items-center w-50"
                                >
                                    <input
                                        :id="
                                            'merge-card-installment' +
                                            destinationCard.current.id
                                        "
                                        class="form-check-input border-muted cursor-pointer"
                                        type="radio"
                                        name="merge-card-installment"
                                        value="Installment:1"
                                        :checked="configObj.Installment == 1"
                                    />
                                    <div class="mx-2">
                                        <p class="text-dark fw-bold mb-0 fs-6">
                                            {{
                                                "merge_card.installment".trans()
                                            }}
                                        </p>
                                        <p class="text-dark mb-0 fs-5">
                                            {{
                                                destinationCard.current
                                                    .Installment == 1
                                                    ? "merge_card.no".trans()
                                                    : "merge_card.yes".trans()
                                            }}
                                        </p>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-dark text-uppercase fw-bold"
                        @click="confirmMergeCard"
                    >
                        {{ "merge_card.modal.merge".trans() }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-light text-uppercase fw-bold"
                        @click="clearDataAndCloseModal"
                    >
                        {{ "merge_card.modal.close".trans() }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, onUpdated, ref } from "vue";
import { useBacklogCardsStore } from "../../js/frontend/stores/backlogCards";
import { useCardSingleStore } from "../../js/frontend/stores/cardSingle";
import { mergeCard } from "../../js/frontend/api/card";
import { fetchSingleCardData } from "../../js/frontend/api/card";

const props = defineProps({
    backlogCards: {
        type: Array,
        required: true,
    },
    originCard: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['reloadPages'])

const select = ref(null);
const modal = ref(null);

const backlogCardsStore = useBacklogCardsStore();
const cardSingleStore = useCardSingleStore();

let destinationCard = ref(null);

let configObj = {
    Brand: 0,
    Label: 0,
    Description: 0,
    PriceFrom: 0,
    Installment: 0,
};

const handleRadioChange = (e) => {
    const [configKey, value] = e.target.value.split(":");
    configObj[configKey] = parseInt(value);
};

async function confirmMergeCard() {
    if (destinationCard == null) {
        return;
    }

    let configArr = [];

    for (let key in configObj) {
        if (configObj.hasOwnProperty(key)) {
            let obj = {};
            obj[key] = configObj[key];
            configArr.push(obj);
        }
    }

    try {
        await mergeCard(
            props.originCard.id,
            destinationCard.value.current.id,
            configObj
        );

        const response = await cardSingleStore.fetchData(destinationCard.value.current.id);

        if (response == 'locked') {
            bootstrap.Offcanvas.getInstance($('#drawerBoxEdit')[0]).hide()
            
            const lockedCardModal = new bootstrap.Modal(
                document.getElementById("lockedCardModal")
            );
            lockedCardModal.show();
        }

        await backlogCardsStore.fetchData(
            destinationCard.value.current.Catalog
        );

        emit('reloadPages');

        cardSingleStore.setNewSplitCard(null);

        destinationCard.value = null;
        configObj = {
            Brand: 0,
            Label: 0,
            Description: 0,
            PriceFrom: 0,
            Installment: 0,
        };

        $(modal.value).modal("hide");
    } catch (error) {
        throw error;
    }
}

onUpdated(() => {
    if (destinationCard.value == null) {
        if ($(select.value)[0].selectize) {
            $(select.value)[0].selectize.destroy();
        }

        let nonVirtualBacklogCards = [];

        if (props.backlogCards) {
            nonVirtualBacklogCards = props.backlogCards.filter(
                (obj) => !obj.isVirtual
            );
        }

        $(select.value).selectize({
            valueField: "id",
            labelField: "Label",
            searchField: ["Label"],
            diacritics: true,
            options: nonVirtualBacklogCards,
            onChange: async function (selectedValue) {
                destinationCard.value = await fetchSingleCardData(
                    selectedValue
                );
            },
            onFocus: function () {
                this.removeOption(props.originCard.id);
            },
        });
    }
});

function clearDataAndCloseModal() {
    destinationCard.value = null;
    $(modal.value).modal("hide");
}
</script>

<style lang="sass" scoped>
.mdi-triangle
    transform: rotate(180deg)
    top: 9px
    right: 18px
    z-index: 1
    pointer-events: none

.radio-btn-pair
    border-bottom: 1px solid #DDE3E9
    padding: 10px 0
</style>
