<template>
    <div class="catalog-page me-3 w-100" ref="root">
        <div class="catalog-page-content px-1 pt-1 border border-1 shadow-sm overflow-auto custom-scrollbar">
            <draggable v-model="cards"
                v-bind="dragOptions"
                group="cards"
                item-key="id"
                class="draggable-container"
                :class="{ 'pointer-events-none': catalogStatus == 'finished' }"
                @add="addCard"
                @change="reorderCards"
                @end="handleDropOnPage"
                ref="draggableComponent"
            >
                <template #item="{element}">
                    <PageCard
                        :element="element"
                        :pageId="pageData.id"
                        :catalogId="catalogId"
                        :activePagesIds="activePagesIds"
                        @productsShow="showProducts"
                    />
                </template>
            </draggable>
        </div>
        <PageControls
            :pageData="pageData"
            :catalogId="catalogId"
            :catalogStatus="catalogStatus"
            :isLastPage="isLast"
            :isRight="isRight"
            @pageAddClick="addPageClick"
            @pageDeleteClick="deletePageClick"
        />

        <DrawerPageComments
            :isRight="isRight"
            :id="pageData.id"
            :catalogStatus="catalogStatus"
            :pageName="pageData.Label"
            :activePagesIds="activePagesIds"
            @commentsDrawerClose="() => activePagesStore.fetchData(props.activePagesIds)"
        />
    </div>
</template>

<script setup>
    import { computed, onBeforeUnmount, onMounted, onUpdated, ref } from 'vue'

    import draggable from './draggable/vuedraggable.umd.min.js'

    import PageControls from './PageControls'
    import PageCard from './PageCard'
    import DrawerPageComments from '../components/Comments/DrawerPageComments'

    import { useActivePagesStore } from '../../js/frontend/stores/activePages'
    import { useBacklogCardsStore } from '../../js/frontend/stores/backlogCards'
    import { addToPage, reorder } from '../../js/frontend/api/card'

    import { dropOnPage } from '../../js/frontend/drag/dragAndDrop'

    const props = defineProps({
        pageData: {
            type: Object,
            required: true
        },
        catalogId: {
            type: Number,
            required: true
        },
        activePagesIds: {
            type: Array,
            required: true
        },
        catalogStatus: {
            type: String,
            required: true
        },
        isLast: {
            type: Boolean,
            required: true
        },
        isRight: {
            type: Boolean,
            required: true
        },
        sortCardsBy: {
            type: String,
            requiđ: true
        }
    })

    const emit = defineEmits(['productsShow', 'pageAddClick', 'pageDeleteClick'])

    const activePagesStore = useActivePagesStore()
    const backlogCardsStore = useBacklogCardsStore()

    const root = ref(null)
    const draggableComponent = ref(null)
    const sortCards = computed(() => props.sortCardsBy)

    const cards = computed(() => {
        if (sortCards.value == 'projection') {
            return props.pageData.catalogCards.sort((a, b) => a.projection < b.projection ? '1' : '-1')
        } else {
            return props.pageData.catalogCards.sort((a, b) => a.Position > b.Position ? '1' : '-1')
        }
    })

    const dragOptions = computed(() => {
        return {
            animation: 200,
            group: "description",
            disabled: false,
            ghostClass: "ghost",
            selectedClass: "multi-drag",
            multiDrag: true,
            handle: ".check-input, .form-check-label",
            forceFallback: true,
            sort: sortCards.value == 'projection' ? false : true,
            scrollSensitivity: 100,
            scrollSpeed: 10000
        }
    })

    onMounted(() => {
        initTooltips()
    })

    onUpdated(() => {
        destroyTooltips()
        initTooltips()
    })

    onBeforeUnmount(() => {
        destroyTooltips()
    })

    function showProducts(emitData) {
        emit('productsShow', emitData)
    }

    function addPageClick(emitData) {
        emit('pageAddClick', emitData)
    }

    function deletePageClick(emitData) {
        emit('pageDeleteClick', emitData)
    }

    function initTooltips() {
        $(root.value).find('.with-tooltip').tooltip('enable')
    }

    function destroyTooltips() {
        $(root.value).find('.with-tooltip').tooltip('dispose')
    }

    async function addCard(event) {
        const cardId = event.item.__draggable_context.element.id
        const cameFromBacklog = $(event.from).hasClass('draggable-container-sidebar')
        const multiple = event.items ? event.items.length > 1 : false

        let cardIds = []

        try {
            if (multiple) {
                cardIds = event.items.map(item => item.__draggable_context.element.id)
            } else {
                cardIds = [cardId]
            }

            await addToPage(props.pageData.id, cardIds, event.newIndex + 1)
            
            activePagesStore.fetchData(props.activePagesIds)

            if (cameFromBacklog) {
                backlogCardsStore.fetchData(props.catalogId)
            }

        } catch (error) {
            window.location.reload()    // TODO: maybe find a better way to undo drag'n'drop changes on error
            throw error
        }
    }

    async function reorderCards(event) {
        if (event.moved) {
            const order = draggableComponent.value.modelValue.map(card => card.id)

            const oldIndex = event.moved.oldIndex
            const newIndex = event.moved.newIndex

            array_move(order, oldIndex, newIndex)

            try {
                await reorder(order)

                activePagesStore.fetchData(props.activePagesIds)
                
            } catch (error) {
                throw error
            }
        }
    }

    async function handleDropOnPage(e) {
        await dropOnPage(e)

        activePagesStore.fetchData(props.activePagesIds)
    }

    function array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            const k = new_index - arr.length + 1
            while (k--) {
                arr.push(undefined)
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
    }
</script>

<style lang="sass" scoped>
    .catalog-page
        max-width: 652px
        
        .name-submit-button
            margin-top: -3px

        .catalog-page-content
            min-height: 89px

            .draggable-container
                min-height: 75px

    .pointer-events-none
        pointer-events: none !important
</style>
