import { hotReload } from './hotReload'
import './updateUrlParameter'
import DashboardSearch from '../search/dashboardSearch'
import Pagination from '../pagination/simplePagination'
import DashboardValidityFilter from '../filters/dashboardValidityFilter'
import DashboardStatusFilter from '../filters/dashboardStatusFilter'

export async function dashboardMakeRequest(
    paramKey, 
    paramValue, 
    clearSearchParam = false, 
    clearPagination = false
) {
    let url = window.location.href
                
    if (clearSearchParam) {
        url = updateUrlParameter(url, paramKey, '')
    } else {
        url = updateUrlParameter(url, paramKey, paramValue)
    }

    if (clearPagination) {
        url = updateUrlParameter(url, 'page', '')
    }

    if (typeof window.history.pushState != 'undefined') {
        window.history.pushState(null, null, url)
    }

    destroyEverything()
    
    await hotReload('.dashboard-page')

    initializeEverything()
}

function destroyEverything() {
    // dashboard search
    $('.dashboard-search').off('keydown')
    $('.dashboard-search-button').off('click')
    $('.search-term-container').off('click')

    // pagination
    $('.simple-pagination').pagination('destroy')

    // table tooltips
    $('.avatar-title').tooltip('dispose')

    // dashboard validity filter
    $('.dashboard-page .datepicker')[0]._flatpickr.destroy()

    // dashboard status filter
    $('.dashboard-page .dashboard-status-filter')[0].selectize.destroy()
}

function initializeEverything() {
    new DashboardSearch($('.dashboard-search-container'))
    new Pagination($('.simple-pagination'))
    new DashboardValidityFilter($('.dashboard-validity-container'))
    new DashboardStatusFilter($('.dashboard-status-filter-container'))

    $('.avatar-title').tooltip('enable')
}