import { hotReload } from '../helpers/hotReload'
import { updateStats } from '../api/catalog'
import { showErrorToast } from '../helpers/generalErrorHandler'

class UpdateCatalogDataModal {
    constructor(DomElem = []) {
        if (DomElem.length == 0) {
            return false
        }

        this.DomElem = DomElem
        this.select = this.DomElem.find('.catalog-modal-status-filter')
        this.pagesInput = this.DomElem.find('#number-of-pages-input')
        this.arrow = this.DomElem.find('.mdi-triangle')
        this.datepicker = this.DomElem.find('.datepicker')
        this.submitButton = this.DomElem.find('.update-catalog-data')
        this.selectedStatus = this.select.val()
        this.newPagesNumber = 0
        this.selectedDate = this.datepicker.val()

        this.initFilter()
        this.initNumberInput()
        this.initDatepicker()
        this.initSubmitEvent()
    }

    initFilter() {
        const self = this

        self.select.selectize({
            maxItems: 1,
            onChange: function(value) {
                self.selectedStatus = value
            },
            onDropdownOpen: function() {
                self.arrow.removeClass('rotate-180')
            },
            onDropdownClose: function() {
                self.arrow.addClass('rotate-180')
            }
        })

        this.DomElem.find('.selectize-input').off('click')
    }

    initNumberInput() {
        this.pagesInput.on('blur', function() {
            const min = parseInt($(this).attr('min'))
            const val = Math.floor(parseInt($(this).val()))

            if (val < min) $(this).val(min)
            else $(this).val(val)
        })
    }

    initDatepicker() {
        this.datepicker.flatpickr({
            dateFormat: 'd.m.Y',
            appendTo: this.datepicker.parent()[0],
            static: true,
            onChange: function(selectedDates, dateStr, instance) {
                this.selectedDate = dateStr
            }
        })
    }

    initSubmitEvent() {
        this.submitButton.on('click', async () => {
            this.updateSelectedDate()
            this.updateSelectedStatus()
            this.setNewPagesNumber()

            try {
                await updateStats(
                    this.submitButton.data('catalogId'),
                    this.selectedStatus,
                    this.newPagesNumber,
                    this.selectedDate
                )
                
                if (this.selectedStatus || this.newPagesNumber > 0) {
                    window.location.reload()
                } else {
                    hotReload(['.sub-header', '#catalogUsersModal'])
                }

            } catch (error) {
                showErrorToast(error)
                throw error
            }
        })
    }

    updateSelectedDate() {
        this.selectedDate = this.datepicker.val()
    }

    updateSelectedStatus() {
        if (this.select.val() == 'null') {
            this.selectedStatus = ''
        } else {
            this.selectedStatus = this.select.val()
        }
    }

    setNewPagesNumber() {
        this.newPagesNumber = this.pagesInput.val() - this.pagesInput.attr('min')
    }
}

export default UpdateCatalogDataModal