<template>
    <div class="modal fade" id="addPageModal" tabindex="-1" aria-labelledby="addPageModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body text-black">
                    <h3 class="modal-title text-black mb-3" id="addPageModalLabel">{{ 'add_page.modal.title'.trans() }}</h3>
                    <h5>
                        {{ 'add_page.modal.text'.trans() }}
                        <span class="fw-bold">{{ 'add_page.modal.page_number'.trans() }} {{ pageData.pagePosition }}</span>?
                    </h5>
                </div>
                <div class="modal-footer">
                    <button @click="addCatalogPage()"
                        type="button"
                        class="btn btn-dark text-uppercase fw-bold"
                        data-bs-dismiss="modal"
                    >
                        {{ 'add_page.modal.confirm'.trans() }}
                    </button>
                    <button type="button" class="btn btn-light text-uppercase fw-bold" data-bs-dismiss="modal">
                        {{ 'add_page.modal.close'.trans() }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { usePageListStore } from '../../js/frontend/stores/pageList'
    import { addPage } from '../../js/frontend/api/page'
    import { hotReload } from '../../js/frontend/helpers/hotReload'

    const props = defineProps({
        pageData: {
            type: Object,
            required: true
        },
        catalogId: {
            type: Number,
            required: true
        }
    })

    const pageListStore = usePageListStore()

    async function addCatalogPage() {
        const newPagesInput = $('#number-of-pages-input')
        const minVal = parseInt(newPagesInput.attr('min'))
        const val = parseInt(newPagesInput.val())

        try {
            await addPage(props.pageData.pageId, 'after')

            hotReload('#numberOfPagesContainer')

            pageListStore.fetchData(props.catalogId)
            
            newPagesInput.attr('min', minVal + 1)
            newPagesInput.val(val + 1)

        } catch (error) {
            throw error
        }
    }
</script>