import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useSplideCarouselStore = defineStore('splideCarousel', () => {
    const currentSlideIndex = ref(-1)
    const getCurrentSlideIndex = computed(() => currentSlideIndex)

    function goToSlide(index) {
        currentSlideIndex.value = index
    }

    return {
        getCurrentSlideIndex,
        goToSlide
    }
})