<template>
    <div class="modal fade" id="cardProductsModal" tabindex="-1" aria-labelledby="cardProductsModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div v-if="!productsLoading" class="modal-content p-3">
                <div class="modal-body text-black">
                    <h3 class="modal-title text-black mb-1 fw-bold" id="cardProductsModalLabel">{{ cardTitle }}</h3>
                    <p class="fw-bold text-uppercase fs-6 mb-1 text-muted">
                        {{ productsCount }}
                        {{ 'card_products.modal.products_count'.trans() }}
                    </p>
                    <table v-if="productsCount > 0" class="w-100 mt-4">
                        <thead>
                            <tr>
                                <th class="fw-bold text-uppercase fs-6 mb-1 text-muted pb-1">
                                    {{ 'card_products.modal.name'.trans() }}
                                </th>
                                <th class="fw-bold text-uppercase fs-6 mb-1 text-muted pb-1">
                                    {{ 'card_products.modal.code'.trans() }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in products" :key="product.id"
                                class="border-bottom"
                            >
                                <td class="fs-5 text-dark py-1">{{ product.name }}</td>
                                <td class="fs-5 text-dark py-1">{{ product.code }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-dark text-uppercase fw-bold" data-bs-dismiss="modal">
                        {{ 'card_products.modal.close'.trans() }}
                    </button>
                </div>
            </div>
            <div v-else class="modal-content p-3">
                <div class="spinner-wrapper d-flex">
                    <span class="spinner-border spinner-border mx-auto my-4" role="status"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed, ref } from 'vue'
    import { useCardStore } from '../../js/frontend/stores/card'

    const cardStore = useCardStore()

    const props = defineProps({
        cardTitle: {
            type: String,
            required: true,
        }
    })

    const products = ref(cardStore.getProducts)
    const productsLoading = ref(cardStore.isLoadingProducts)

    const productsCount = computed(() => products.value.length)

</script>

<style lang="sass" scoped>

</style>