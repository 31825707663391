<template>
    <div class="drawer-box-edit-container">
        <div
            id="drawerBoxOverview"
            class="offcanvas offcanvas-end"
            tabindex="-1"
            aria-labelledby="drawerBoxOverviewLabel"
            ref="offcanvas"
        >
            <div v-if="isLoading" class="spinner-wrapper">
                <span
                    class="spinner-border spinner-border me-1"
                    role="status"
                ></span>
            </div>

            <template v-else>
                <div
                    class="offcanvas-header drawer-box-edit-header border-bottom mx-4 px-0 pb-2"
                >
                    <h2
                        id="drawerBoxOverviewLabel"
                        class="offcanvas-title fw-bold text-black mt-1"
                    >
                        {{ "drawer.box.overview.title".trans() }}
                    </h2>
                    <button
                        class="btn-close text-reset"
                        type="button"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="offcanvas-body drawer-box-edit-body p-4">
                    <ul class="nav nav-tabs nav-bordered nav-justified w-50">
                        <li class="nav-item">
                            <a
                                class="nav-link fw-bold text-uppercase text-dark active"
                                href="#data-tab-overview"
                                data-bs-toggle="tab"
                                aria-expanded="false"
                            >
                                {{ "box.tab.data".trans() }}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link fw-bold text-uppercase text-dark"
                                :class="{ 'extra-margin d-flex ms-4': draftData.unreadCommentCount > 0 }"
                                href="#comments-tab-overview"
                                data-bs-toggle="tab"
                                aria-expanded="true"
                            >
                                {{ "box.tab.comments".trans() }}
                                <i v-if="draftData.unreadCommentCount > 0" class="mdi mdi-message position-relative fs-4 ms-1">
                                    <span class="comment-number position-absolute text-white fs-7">
                                        {{ draftData.unreadCommentCount }}
                                    </span>
                                </i>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <BoxOverviewDataTab
                            :draftData="draftData"
                            :currentData="currentData"
                        />
                        <BoxOverviewCommentsTab :id="currentData.id" :isFinished="catalogIsFinished" />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from "vue";
    import { useCardSingleStore } from "../../../js/frontend/stores/cardSingle";

    import BoxOverviewDataTab from "./BoxOverviewDataTab";
    import BoxOverviewCommentsTab from "./BoxOverviewCommentsTab";

    const cardSingleStore = useCardSingleStore();

    const props = defineProps({
        catalogIsFinished: {
            type: Boolean,
            required: true
        }
    })

    const emit = defineEmits(['reloadOverviewPages'])

    const draftData = ref(cardSingleStore.getDraftData);
    const currentData = ref(cardSingleStore.getCurrentData);
    const isLoading = ref(cardSingleStore.isLoading);

    const offcanvas = ref(null)

    onMounted(() => {
        $(offcanvas.value).on("hidden.bs.offcanvas", () => {
            emit('reloadOverviewPages')
        });
    })
</script>

<style lang="sass" scoped>
    .spinner-wrapper
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center

        .spinner-border
            width: 4.5rem
            height: 4.5rem

    .extra-margin
        margin-bottom: -5px

        &.active
            margin-bottom: -1px

    .comment-number
        left: 50%
        transform: translateX(-50%)
        top: 4px
        font-style: normal
</style>
