import { addToPage } from '../api/card'

async function dropOnPage(e) {
    const dropTarget = $(e.originalEvent.target).closest('.card-drop-target')

    if (dropTarget.length == 0) return

    const pageId = dropTarget.data('pageId')
    const cardId = e.item.__draggable_context.element.id
    const multiple = e.items ? e.items.length > 1 : false

    let cardIds = []

    try {
        if (multiple) {
            cardIds = e.items.map(item => item.__draggable_context.element.id)
        } else {
            cardIds = [cardId]
        }

        await addToPage(pageId, cardIds, 1)

    } catch (error) {
        window.location.reload()
        throw error
    }
}

export {
    dropOnPage
}