import { dashboardMakeRequest } from '../helpers/dashboardMakeRequest'

class Pagination {
    constructor(DomElem = []) {
        if (DomElem.length == 0) {
            return false
        }

        this.DomElem = DomElem
        this.HtmlData = this.DomElem.data()
        this.currentPage = this.HtmlData.currentPage || 1

        this.initPagination()
    }

    initPagination() {
        const self = this

        this.DomElem.pagination({
            pages: this.HtmlData.pages || 1,
            currentPage: this.currentPage,
            prevText: this.HtmlData.prevText,
            nextText: this.HtmlData.nextText,
            onPageClick(number, event) {
                self.paginate(number, event)
            }
        })
    }

    paginate(number, event) {
        event.preventDefault()
        this.currentPage = number
        dashboardMakeRequest(this.HtmlData.urlParam, this.currentPage, false, false)
    }
}

export default Pagination