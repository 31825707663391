import axios from 'axios'
import DashboardSearch from './search/dashboardSearch'
import Pagination from './pagination/simplePagination'
import DashboardValidityFilter from './filters/dashboardValidityFilter'
import DashboardStatusFilter from './filters/dashboardStatusFilter'
import UpdateUsersModal from './modals/updateUsersModal'
import UpdateCatalogDataModal from './modals/updateCatalogDataModal'
import DownloadCatalog from './modals/downloadCatalog'

axios.defaults.baseURL = window.catalogManagerUrl || axios.defaults.baseURL;

// Vue
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import CatalogSingle from '../../vue/CatalogSingle.vue'

// Translations in JS, used for Vue
import Translations from './helpers/Translations'

$(document).ready(async function() {
    await Translations.init()

    new DashboardSearch($('.dashboard-search-container'))

    new Pagination($('.simple-pagination'))

    new DashboardValidityFilter($('.dashboard-validity-container'))
    new DashboardStatusFilter($('.dashboard-status-filter-container'))

    new UpdateUsersModal($('#catalogUsersUpdateModal'))
    new UpdateCatalogDataModal($('#catalogUpdateModal'))

    new DownloadCatalog($('#drawerDownload'))

    const app = createApp(CatalogSingle, { ...$('#catalog-single-vue-app').data() })

    app.use(createPinia())
    app.mount('#catalog-single-vue-app')
})