<template>
    <div class="modal fade" id="deletePageModal" tabindex="-1" aria-labelledby="deletePageModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body text-black">
                    <h3 class="modal-title text-black mb-3" id="deletePageModalLabel">{{ 'delete_page.modal.title'.trans() }}</h3>
                    <h5>
                        {{ 'delete_page.modal.text'.trans() }}
                        <span class="fw-bold">{{ 'delete_page.modal.page_number'.trans() }} {{ pageData.pagePosition }}</span>?
                    </h5>
                    <h5>
                        {{ 'delete_page.modal.explanation'.trans() }}
                    </h5>
                </div>
                <div class="modal-footer">
                    <button @click="deleteCatalogPage()"
                        type="button"
                        class="btn btn-dark text-uppercase fw-bold"
                        data-bs-dismiss="modal"
                    >
                        {{ 'delete_page.modal.confirm'.trans() }}
                    </button>
                    <button type="button" class="btn btn-light text-uppercase fw-bold" data-bs-dismiss="modal">
                        {{ 'delete_page.modal.close'.trans() }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { usePageListStore } from '../../js/frontend/stores/pageList'
    import { deletePage } from '../../js/frontend/api/page'
    import { hotReload } from '../../js/frontend/helpers/hotReload'

    const props = defineProps({
        pageData: {
            type: Object,
            required: true
        },
        catalogId: {
            type: Number,
            required: true
        }
    })

    const pageListStore = usePageListStore()

    async function deleteCatalogPage() {
        const newPagesInput = $('#number-of-pages-input')
        const minVal = parseInt(newPagesInput.attr('min'))
        const val = parseInt(newPagesInput.val())

        try {
            await deletePage(props.pageData.pageId)

            hotReload('#numberOfPagesContainer')

            pageListStore.fetchData(props.catalogId)

            newPagesInput.attr('min', minVal - 1)
            newPagesInput.val(val - 1)

        } catch (error) {
            throw error
        }
    }
</script>