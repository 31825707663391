import axios from 'axios'
import { mapCardData, mapCardDataOverview } from '../mappers/card'
import { showErrorToast } from '../helpers/generalErrorHandler'

async function fetchPages(catalogId) {
    if (!catalogId) return []

    try {
        const response = await axios.get(`/api/catalog/${catalogId}/catalog-pages`)

        if (typeof(response.data) != 'object') {
            return []
        }

        if (!response.data.data) return []

        const data = response.data.data.map(item => {
            return {
                id: item.id || null,
                Position: item.Position || null,
                Label: item.Label || null,
                CardStatuses: item.CardStatuses || {},
                totalPageCommentCount: item.totalPageCommentCount || 0,
                unreadCardCommentCount: item.unreadCardCommentCount || 0,
                unreadPageCommentCount: item.unreadPageCommentCount || 0
            }
        })

        return data || []

    } catch (error) {
        showErrorToast(error)
        throw error
    }
}

async function fetchPage(pageId) {
    if (!pageId) return []

    try {
        const response = await axios.get(`/api/catalog/catalog-page/${pageId}`)

        const data = {
            id: response.data.data.id || null,
            Katalog: response.data.data.Katalog || null,
            PageType: response.data.data.PageType || null,
            Label: response.data.data.Label || null,
            Position: response.data.data.Position || null,
            catalogCards: mapCardData(response.data.data.catalogCards),
            totalPageCommentCount: response.data.data.totalPageCommentCount || 0,
            unreadCardCommentCount: response.data.data.unreadCardCommentCount || 0,
            unreadPageCommentCount: response.data.data.unreadPageCommentCount || 0
        }

        return data || []

    } catch (error) {
        showErrorToast(error)
        throw error
    }
}

async function fetchOverviewPage(pageId) {
    if (!pageId) return []

    try {
        const response = await axios.get(`/api/catalog/catalog-page/${pageId}?extra=1`)

        const data = {
            id: response.data.data.id || null,
            Katalog: response.data.data.Katalog || null,
            PageType: response.data.data.PageType || null,
            Label: response.data.data.Label || null,
            Position: response.data.data.Position || null,
            catalogCards: mapCardDataOverview(response.data.data.catalogCards),
            totalPageCommentCount: response.data.data.totalPageCommentCount || 0,
            unreadCardCommentCount: response.data.data.unreadCardCommentCount || 0,
            unreadPageCommentCount: response.data.data.unreadPageCommentCount || 0
        }

        return data || []

    } catch (error) {
        showErrorToast(error)
        throw error
    }
}

async function updateStats(pageId, pageName, pageType) {
    if (!pageId || !pageName || !pageType) return

    try {
        await axios.post('/api/catalog/catalog-page/update-page', {
            'pageId': pageId,
            'newName': pageName,
            'newPageType': pageType
        })

    } catch (error) {
        showErrorToast(error)
        throw error
    }
}

async function addPage(pageId, pagePosition = 'after') {
    if (!pageId || !pagePosition) return

    try {
        await axios.post('/api/catalog/catalog-page/append-page', {
            'pageId': pageId,
            'position': pagePosition
        })

    } catch (error) {
        showErrorToast(error)
        throw error
    }
}

async function deletePage(pageId) {
    if (!pageId) return

    try {
        await axios.post('/api/catalog/catalog-page/delete-page', {
            'pageId': pageId
        })

    } catch (error) {
        showErrorToast(error)
        throw error
    }
}

async function reorderPages(pageIds) {
    if (!pageIds) return

    try {
        await axios.post('/api/catalog/arrange-pages', {
            'pageIds': pageIds
        })

    } catch (error) {
        showErrorToast(error)
        throw error
    }
}

export { fetchPages, fetchPage, fetchOverviewPage, updateStats, addPage, deletePage, reorderPages }