import { defineStore } from "pinia";
import { ref, computed } from "vue";

import { fetchBacklog, fetchAll } from "../api/card";
import {
    fetchTopics,
    fetchDepartmentMasks,
    fetchRobniProgram,
} from "../api/catalog";

export const useBacklogCardsStore = defineStore("backlogCards", () => {
    const data = ref(null);
    const allCards = ref(null);
    const time = ref(null);
    const loading = ref(true);
    const payload = ref({});

    const topics = ref(null);
    const departmentMasks = ref(null);
    const robniProgram = ref(null);
    const loadingFilters = ref(true);

    const getData = computed(() => data);
    const getAllCards = computed(() => allCards);
    const getTime = computed(() => time);
    const isLoading = computed(() => loading);

    const getTopics = computed(() => topics);
    const getDepartmentMasks = computed(() => departmentMasks);
    const getRobniProgram = computed(() => robniProgram);
    const isLoadingFilters = computed(() => loadingFilters);
    const getPayload = computed(() => payload);

    async function fetchData(catalogId) {
        try {
            loading.value = true;

            const response = await fetchBacklog(catalogId, payload.value);

            data.value = response.data;
            time.value = response.time;

            loading.value = false;
        } catch (error) {
            loading.value = false;
            throw error;
        }
    }

    async function fetchAllCards(catalogId) {
        try {
            loading.value = true;

            const response = await fetchAll(catalogId);

            allCards.value = response;

            loading.value = false;
        } catch (error) {
            loading.value = false;
            throw error;
        }
    }

    async function fetchSingleFilterData(name, catalogId) {
        try {
            loadingFilters.value = true;

            if (name == "topics") {
                const response = await fetchTopics(catalogId);
                topics.value = response;
            } else if (name == "department-masks") {
                const response = await fetchDepartmentMasks(catalogId);
                departmentMasks.value = response;
            } else if (name == "robni-program") {
                const response = await fetchRobniProgram(catalogId);
                robniProgram.value = response;
            }

            loadingFilters.value = false;
        } catch (error) {
            loadingFilters.value = false;
            throw error;
        }
    }

    function setPayload(payloadObj) {
        payload.value = payloadObj;
    }

    function setLoading(isLoading) {
        loading.value = isLoading;
    }

    return {
        getData,
        getAllCards,
        getTopics,
        getDepartmentMasks,
        getRobniProgram,
        getTime,
        isLoading,
        isLoadingFilters,
        fetchData,
        fetchAllCards,
        fetchSingleFilterData,
        setLoading,
        setPayload,
        getPayload,
    };
});
