import { dashboardMakeRequest } from '../helpers/dashboardMakeRequest'

class DashboardStatusFilter {
    constructor(DomElem = []) {
        if (DomElem.length == 0) {
            return false
        }

        this.DomElem = DomElem
        this.bootstrapDropdown = this.DomElem.find('.dropdown-toggle')
        this.select = this.DomElem.find('.dashboard-status-filter')
        this.clearButton = this.DomElem.find('.clear-button')
        this.submitButton = this.DomElem.find('.submit-button')
        this.preselect = this.select.data('preselect').split(',')
        
        this.statuses = []

        this.initFilter()
        this.initEvents()
    }

    initFilter() {
        const self = this

        self.select.selectize({
            maxItems: null,
            closeAfterSelect: true,
            onInitialize: function() {
                this.setValue(self.preselect)
            },
            onChange: function(values) {
                self.statuses = values
                self.submitButton.removeClass("disabled")
            }
        })

        if (self.hasValue()) {
            self.submitButton.removeClass("disabled")
        } else {
            self.submitButton.addClass("disabled")
        }
    }

    initEvents() {
        this.clearButton.on('click', () => {
            if (!self.hasValue()) return

            this.bootstrapDropdown.dropdown('toggle')

            this.select[0].selectize.clear()

            dashboardMakeRequest('status', '', false, true)
        })

        this.submitButton.on('click', () => {
            this.bootstrapDropdown.dropdown('toggle')
            
            if (this.statuses.length == 0) {
                dashboardMakeRequest('status', '', false, true)
            } else {
                dashboardMakeRequest('status', this.statuses.join(','), false, true)
            }
        })

        const self = this
        this.DomElem.on('click', '.item', function() {
            self.select[0].selectize.removeItem($(this).data('value'))
            self.select[0].selectize.refreshOptions()
        })
    }

    hasValue() {
        return this.statuses.length > 0
    }
}

export default DashboardStatusFilter