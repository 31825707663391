<template>
    <div class="loyalty-data bg-loyalty border-loyalty border-1 text-black fw-bold fs-6 text-uppercase text-center rounded-1">
        {{ 'loyalty.card.is_loyalty'.trans() }}
    </div>
</template>

<script setup>
</script>

<style lang="sass" scoped>
    .loyalty-data
        padding-top: 2px

    .bg-loyalty
        background-color: #DEEBB8 !important

    .border-loyalty
        border: 1px solid #C1DE72 !important
</style>