import axios from 'axios'

/**
 * Helper function for hot swapping a part of the page without triggering a browser reload.
 * 
 * @param {string|string[]} elements A single selector or an array of selectors for the DOM elements whose html content will be replaced.
 */
export async function hotReload(elements) {
    try {
        const response = await axios.get(window.location.href)
        const data = $(response.data)

        // if the parameter is an array of selectors
        if (Array.isArray(elements)) {
            elements.forEach(element => {

                // if there are multiple DOM elements with same selector, swap html of every one of them
                if ($(element).length > 1) {
                    let newElements = data.find(element)

                    $(element).map((index, elem) => {
                        $(elem).html($(newElements[index]).html())
                    })

                } else {
                    let newHTML = data.find(element).html() || ''
                    $(element).html(newHTML)
                }
            })

        // if the parameter is a single selector
        } else {

            // if there are multiple DOM elements with same selector, swap html of every one of them
            if ($(elements).length > 1) {
                let newElements = data.find(elements)

                $(elements).map((index, element) => {
                    $(element).html($(newElements[index]).html())
                })

            } else {
                let newHTML = data.find(elements).html() || ''
                $(elements).html(newHTML)
            }
        }
        
    } catch(e) {
        throw e
    }
}