<template>
    <div class="catalog-page w-100" :class="{ 'me-3': !isRight }">
        <div class="catalog-page-content d-flex flex-wrap ps-3 pb-3 border border-1 shadow-sm">
            <OverviewPageCard
                v-for="card in pageData.catalogCards"
                :element="card"
                :pageId="pageData.id"
                :catalogId="catalogId"
                @cardTVselected="selectTVcard"
                :key="card.id"
            />
        </div>
        <div class="catalog-bottom-content">
            <div class="d-flex mt-3">
                <div class="page-number-container me-2 mt-auto mb-2">
                    <span class="bg-dark p-1 px-2 rounded-pill text-white fw-bold">
                        {{ pageData.Position }}
                    </span>
                </div>
                <div class="page-name-container position-relative w-100">
                    <p class="mb-0 fw-bold text-dark fs-3">
                        {{ pageData.Label }}
                    </p>
                </div>
                <div class="ms-auto me-2">
                    <a class="d-flex align-items-center text-dark cursor-pointer"
                        data-bs-toggle="offcanvas"
                        :data-bs-target="isRight ? '#drawerPageCommentsOverviewRight' : '#drawerPageCommentsOverviewLeft'"
                        :aria-controls="isRight ? 'drawerPageCommentsOverviewRight' : 'drawerPageCommentsOverviewLeft'"
                    >
                        <i class="mdi mdi-comment-text-multiple-outline me-1 fs-3"
                            :class="{ 'text-red': pageData.unreadPageCommentCount > 0 }"
                        ></i>
                        <span class="fs-5 text-decoration-underline cursor-pointer nowrap">
                            {{ 'catalog.page.comments'.trans() }} ({{ pageData.totalPageCommentCount }})
                        </span>
                    </a>
                </div>
            </div>
        </div>

        <DrawerPageCommentsOverview
            :isRight="isRight"
            :id="pageData.id"
            :isFinished="catalogStatus == 'finished'"
            :pageName="pageData.Label"
            :activePagesIds="activePagesIds"
            @commentsDrawerClose="() => activePagesStore.fetchOverviewData(props.activePagesIds)"
        />
    </div>
</template>

<script setup>
    import { computed } from 'vue'
    import OverviewPageCard from './OverviewPageCard'
    import DrawerPageCommentsOverview from '../components/Comments/DrawerPageCommentsOverview'
    import { useActivePagesStore } from '../../js/frontend/stores/activePages'
    import { useCommentsStore } from '../../js/frontend/stores/comments'

    const props = defineProps({
        pageData: {
            type: Object,
            required: true
        },
        catalogId: {
            type: Number,
            required: true
        },
        isRight: {
            type: Boolean,
            required: true
        },
        activePagesIds: {
            type: Array,
            required: true
        },
        catalogStatus: {
            type: String,
            required: true
        }
    })

    const emit = defineEmits(['cardTVselected'])

    const commentsStore = useCommentsStore()
    const activePagesStore = useActivePagesStore()

    const commentsLength = computed(() => {
        if (props.isRight) return commentsStore.getActivePagesComments.value[1].length
        else return commentsStore.getActivePagesComments.value[0].length
    })

    function selectTVcard() {
        emit('cardTVselected')
    }

</script>

<style lang="sass" scoped>
    .catalog-page-content
        min-height: 89px
        max-height: initial
</style>