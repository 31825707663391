function mapCardCommentsData(data) {
    if (!data) return []

    const dataMapped = data.map(comment => {
        return {
            'id': comment.id || null,
            'author': comment.author || null,
            'timestamp': comment.updatedAt || null,
            'comment': comment.content || null,
            'editable': comment.permission.permission || false,
            'metadata': comment.metadata || {}
        }
    })

    return dataMapped
}

function mapPageCommentsData(data) {
    if (!data) return []

    const dataMapped = data.map(comment => {
        return {
            'id': comment.id || null,
            'author': comment.author || null,
            'timestamp': comment.updatedAt || null,
            'comment': comment.content || null,
            'editable': comment.permission.permission || false
        }
    })

    return dataMapped
}

export { mapCardCommentsData, mapPageCommentsData }